import React from "react";
import { Link } from "react-router-dom";
import "./menu-link.scss";
import clsx from "clsx";

const MenuLink = ({
    to,
    text,
    onClick,
    active,
    danger,
    children,
    isDisabled,
}) => {
    return (
        <>
            {isDisabled ? (
                <div className="menu-link menu-blur">
                    {children}
                    {text}
                </div>
            ) : (
                <Link
                    className={clsx("menu-link", {
                        "text-button-danger": danger,
                        active: active,
                    })}
                    to={to}
                    onClick={onClick}
                >
                    {children}
                    <span className="menu-link__text">{text}</span>
                </Link>
            )}
        </>
    );
};

export default MenuLink;
