import secureLocalStorage from "react-secure-storage"

let instance : LocalStorage;

class LocalStorage {
    constructor() {
        if(instance) {
            throw new Error("We have only 1 local storage object")
        }
        instance = this
    }

    getItem = (key : string) => {
        if(import.meta.env.VITE_STAGE !== 'local') {
            return secureLocalStorage.getItem(key)
        } else {
            let item = localStorage.getItem(key)
            try {
                if(typeof(item) === 'string') {
                    return JSON.parse(item)
                }
                return item
            } catch(e) {
                return item
            }
        }
    }
    
    setItem = (key : string, value : string) => {
        if(import.meta.env.VITE_STAGE !== 'local') {
            secureLocalStorage.setItem(key, value)
        } else {
            localStorage.setItem(key, typeof(value) === 'string' ? value : JSON.stringify(value))
        }
    }
    
    removeItem = (key : string) => {
        if(import.meta.env.VITE_STAGE !== 'local') {
            secureLocalStorage.removeItem(key)
        } else {
            localStorage.removeItem(key)
        }
    }
    
    clear = () => {
        if(import.meta.env.VITE_STAGE !== 'local') {
            secureLocalStorage.clear()
        } else {
            localStorage.clear()
        }
    }
}

const singletonLocalStorage = Object.freeze(new LocalStorage());
export default singletonLocalStorage