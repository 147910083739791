import React from "react";
import { ReactComponent as UpgradeSu } from "../../../static/images/upgrade-subs.svg";
import { Stack } from "@mui/material";
import ScalersTypography from "@/components/atoms/typography/typography";
import "@/../static/css/candidates-new.scss";
import { CallOutlined } from "@material-ui/icons";
import ScalersButton from "@/components/atoms/button/button";
import CalendlyModal from "@/components/Modals/CalendlyModal";
import { useTranslation } from "react-i18next";
import { Factory, Pages } from "@/mixpanel-tracking/hp/factory";
import { CandidateListTrack } from "@/mixpanel-tracking/hp/candidate-list-tracking";

const BookSubscriptionCall: React.FC = () => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const factory = new Factory({
        count: 0,
        filters: [],
        candidates: [],
    });
    const track = factory.get_tracker(
        Pages.CANDIDATE_LIST
    ) as CandidateListTrack;

    const bookCall = () => {
        track.bookSubscriptionCallButtonClicked("Candidate List");
        setOpen(true);
    };
    return (
        <>
            <div className={"content-container"} id="candidates-list">
                <div
                    className="content"
                    style={{
                        background: "white",
                        borderRadius: "32px",
                        boxShadow:
                            "0px 8px 24px 0px rgba(108, 47, 131, 0.20), 0px 0px 1px 0px rgba(108, 47, 131, 0.05)",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Stack
                        margin="0 auto"
                        maxWidth="800px"
                        gap="20px"
                        alignItems="center"
                        textAlign="center"
                        padding="10px"
                        height="100%"
                        justifyContent="center"
                    >
                        <UpgradeSu />
                        <ScalersTypography
                            variant="h4"
                            fontWeight={700}
                            color="#2A2731"
                            className="curation-request-title"
                        >
                            {t("marketing-vertical.subscribe.title")}
                        </ScalersTypography>
                        <ScalersTypography
                            variant="body1"
                            fontWeight={500}
                            color="var(--black-300)"
                            className="curation-request-subtitle"
                        >
                            {t("marketing-vertical.subscribe.content")}
                        </ScalersTypography>
                        <ScalersButton
                            startIcon={
                                <CallOutlined style={{ color: "white" }} />
                            }
                            variant="contained"
                            onClick={bookCall}
                            text={t("no_curated.button")}
                            color="primary"
                        />
                    </Stack>
                </div>
            </div>
            <CalendlyModal open={open} setOpen={setOpen} />
        </>
    );
};

export default BookSubscriptionCall;
