import { getUser } from "@/contexts/UserContext";
import React from "react";
import EmployeesError from "./EmployeesError";
import CandidatesError from "./CandidatesError";
import "@/../static/css/error.css";
import DefaultError from "./DefaultError";
import FeedbackModal from "@/components/Modals/FeedbackModal";

export default function Error() {
    const currUser = getUser();

    return (
        <>
            {Object.keys(currUser).length > 0 ? (
                currUser.views == "hp" ? (
                    <EmployeesError />
                ) : (
                    <CandidatesError />
                )
            ) : (
                <DefaultError />
            )}

            {Object.keys(currUser).length > 0 && <FeedbackModal />}
        </>
    );
}
