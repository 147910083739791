import React, { FC, ReactNode } from "react";
import Modal from "./Modal";

interface Props {
    withXButton: boolean;
    children: ReactNode;
}
const ModalTopTitle: FC<Props> = ({ withXButton, children }: Props) => {
    return (
        <div className="top-title">
            {withXButton && <Modal.XButton />}
            <div className="children">{children}</div>
        </div>
    );
};

export default ModalTopTitle;
