import React, { useState } from "react";
import Modal from "./Modal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Box, Button, Stack } from "@mui/material";
import ScalersTypography from "../atoms/typography/typography";
import { useTranslation } from "react-i18next";
import CalendlyModal from "./CalendlyModal";

function FreeTrialModal() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleCloseModal = () => {
        document.getElementById("free-trial-modal").style.display = "none";
    };

    const handleBookCall = () => {
        handleCloseModal();
        setOpen(true);
    };

    return (
        <>
            <Modal type="free-trial-modal">
                <Stack width="100%" gap="12px" alignItems="center">
                    <ErrorOutlineOutlinedIcon />
                    <ScalersTypography
                        variant="subtitle"
                        fontSize="19px"
                        fontWeight={700}
                    >
                        {t("trial-duplicate-job.heading")}
                    </ScalersTypography>
                    <ScalersTypography
                        variant="body2"
                        fontSize="13px"
                        fontWeight={400}
                        color="#55525A"
                    >
                        {t("trial-duplicate-job.subheading")}
                    </ScalersTypography>
                    <Box className="button-group">
                        <Button
                            className="back-button"
                            variant="contained"
                            onClick={handleCloseModal}
                        >
                            {t("trial-duplicate-job.alright")}
                        </Button>
                        <Button variant="contained" onClick={handleBookCall}>
                            {t("trial-duplicate-job.book-call")}
                        </Button>
                    </Box>
                </Stack>
            </Modal>
            <CalendlyModal open={open} setOpen={setOpen} />
        </>
    );
}

export default FreeTrialModal;
