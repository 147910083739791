// setting image colors
const imageAltColors = [
    "var(--success-300)",
    "var(--danger-100)",
    "var(--info-50)",
    "var(--primary-50)",
];

// get random color
export const getRandomColor = () => {
    return imageAltColors[Math.floor(Math.random() * imageAltColors.length)];
};
