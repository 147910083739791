import {MSWDevTools} from 'msw-devtools';
import {db} from "@/testing/mocks/db";
import {handlers} from "@/testing/mocks/handlers";
import React from 'react';
import { setupWorker } from 'msw';
const worker = setupWorker(...handlers);
export const MSWWrapper = ({
                               children,
                           }) => {
    React.useEffect(() => {
        worker.start({
            quiet: true,
        });
        return () => {
            worker.stop();
        };
    }, []);
    return (
        <>
            <MSWDevTools db={db} handlers={handlers}/>
            {children}
        </>
    );
};