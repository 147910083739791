import { useTranslation } from "react-i18next";
import { Mixpanel, captureEvent } from "../Mixpanel";
import { getLookups } from "../../contexts/LookupsStateContext";
import singletonLocalStorage from "../../utils/localStoring";
import {
    prefix_to_object_from_array,
    prefix_to_object,
    getSubscriptionDetails,
    getActiveVertical,
} from "../utils";
import store from "@/core/stores";
import { getUser } from "@/contexts/UserContext";

export class CandidateListTrack {
    state = store.getState();
    constructor(props) {
        this.candidates = props.candidates;
        this.vertical = this.state.selectedVertical.vertical;
        this.props = {
            total_in_list_before_action: props.count,
            page_before_action: parseInt(
                singletonLocalStorage.getItem("curr_page_" + this.vertical)
            ),
            filters: props.filters.current,
        };
        this.lookupOptions = getLookups();
        this.currUser = getUser();
    }

    get t_func() {
        return useTranslation();
    }

    search = (content, results_after) => {
        captureEvent("Name Search", {
            search_content: content,
            count_after_search: results_after[0],
            ...this.props,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    shortlist_candidate = (
        from,
        candidate_details,
        free_trial_data,
        unverified
    ) => {
        captureEvent("Candidate Shortlisted", {
            from: from,
            ...candidate_details,
            ...free_trial_data,
            ...this.props,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
        if (unverified) return;
        Mixpanel.people.increment("total_shortlist_count", 1);
    };
    shortlist_attempt = (candidate_details, free_trial_data) => {
        captureEvent("Candidate Shortlist Attempted", {
            ...candidate_details,
            verified: false,
            ...free_trial_data,
        });
    };
    message_candidate = (from, candidate_details, free_trial_data) => {
        captureEvent("Message Viewed", {
            from: from,
            ...candidate_details,
            ...free_trial_data,
            ...this.props,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
        Mixpanel.people.increment("total_message_viewed", 1);
    };
    delist_candidate_from_list = (
        from,
        reasons,
        candidate_details,
        free_trial_data,
        extraAttributes
    ) => {
        captureEvent("Candidate Delisted ", {
            no_hire_reasons: reasons,
            from: from,
            ...candidate_details,
            ...free_trial_data,
            ...this.props,
            ...extraAttributes,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
        Mixpanel.people.increment("total_delist", 1);
    };
    clear_all_filters = (filters) => {
        captureEvent("All Filters Removed", {
            filters: filters,
            ...this.props,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    filter_added = (
        k,
        data,
        count_after,
        matchPercentage,
        added_from,
        create_job_button_activated
    ) => {
        console.log("ALLEERRTT", getActiveVertical(this.vertical));
        captureEvent("Filter Added", {
            filter: k,
            specific_filter: data[data.length - 1].name_en,
            total_in_list_after_action: count_after,
            percentage_of_the_candidate: matchPercentage,
            create_job_button_activated,
            from: added_from,
            ...this.props,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    filter_removed = (extra_props) => {
        const { k, candidate_count, item, removed_from } = extra_props;
        captureEvent("Filter Removed", {
            filter: k,
            specific_filter: item.name_en,
            removed_filter_from: removed_from,
            total_in_list_after_action: candidate_count,
            ...this.props,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    all_filters_button_click = () => {
        captureEvent("All Filters Button Clicked", {
            ...this.props,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    filters_extra_options_click = (for_filter) => {
        captureEvent("Filters Extra Options Button Clicked", {
            filter: for_filter,
            ...this.props,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    specific_filter_all_removed = (for_filter, candidate_count) => {
        console.log(
            "SPECIFIC FILTER ALL REMOVED",
            getActiveVertical(this.vertical),
            getSubscriptionDetails(this.currUser)
        );
        captureEvent("Specific Filter All Selections Removed", {
            filter: for_filter,
            total_in_list_after_action: candidate_count,
            ...this.props,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    page_changed = (page) => {
        let not_shortlisted = this.candidates.filter(
            (candidate) => !candidate.is_shortlisted
        );
        captureEvent("Candidate List Page Changed", {
            ...this.props,
            to_page: page,
            number_of_candidates_in_page: this.candidates.length,
            number_of_candidates_in_page_not_shortlisted:
                not_shortlisted.length,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    create_job_post_button_clicked = (filters, value) => {
        captureEvent("Create Job Post Button Clicked", {
            ...prefix_to_object_from_array(filters, "filters_applied_"),
            percentage_of_the_candidate: value,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    job_filter_track = (event, data) => {
        captureEvent(event, {
            ...data,
        });
    };
    interview_scheduling_clicked = (candidate) => {
        captureEvent("Interview Scheduling Button Clicked", {
            user_type: "hp",
            from: "Candidate List",
            ...prefix_to_object(candidate, "candidate_"),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };

    candidate_shortlisted = "Candidate Shortlisted";
    create_job_post_button_click = "Create Job Post Button Clicked";
    shortlist_button_clicked = "Shortlist Button Clicked";
    applicant_viewed_from_shortlist = "Applicant Viewed From Shortlist";

    candidateShortlisted(
        from,
        candidate_details,
        free_trial_data,
        verified,
        jobs
    ) {
        captureEvent(this.candidate_shortlisted, {
            from,
            ...candidate_details,
            ...free_trial_data,
            verified,
            ...jobs,
            ...this.props,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    }

    createJobPostButtonClicked(
        from,
        candidate_details,
        free_trial_data,
        verified
    ) {
        captureEvent(this.create_job_post_button_click, {
            from,
            verified,
            ...candidate_details,
            ...free_trial_data,
            ...this.props,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    }

    shortlistButtonClicked(from, candidate_details, company_details) {
        captureEvent(this.shortlist_button_clicked, {
            from,
            ...candidate_details,
            ...company_details,
            ...this.props,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    }

    bookSubscriptionCallButtonClicked(from) {
        captureEvent("Book a Call Button Clicked", {
            from,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    }
}
