import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import "@/../static/css/modal.scss";
import Modal from "./Modal";
import complete_profile_img from "@/../static/images/completeurprofile.svg";
import { useNavigate } from "react-router-dom";

type Props = {
    type: string;
};

const CompleteProfileModal: FC<Props> = ({ type }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Modal type={type} closable={false}>
            <Modal.Illustration image={complete_profile_img} />
            <Modal.Title content={t("complete-ur-profile-modal.title1")} />
            <Modal.SubtitleDetails
                content={t("complete-ur-profile-modal.subtitle")}
            />
            <Modal.SubSubtitle
                content={t("complete-ur-profile-modal.subsubtitle")}
            />
            <Modal.SingleMainAction
                buttonContent={t("complete-ur-profile-modal.main-btn")}
                action={() => {
                    navigate("candidate/complete-placement");
                }}
                type="contained"
            />
        </Modal>
    );
};

export default CompleteProfileModal;
