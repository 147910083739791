import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import {
    needsToFillPlacement,
    bootcamperNeedToContinue,
    genericNeedsToPlacement,
} from "@/utils/utilFunctions";
import LogoEn from "../../../../static/images/small-logo.svg";
import LogoEnWithText from "../../../../static/images/logo.svg";
import LogoAr from "../../../../static/images/small-logo.svg";
import LogoArWithText from "../../../../static/images/logo.svg";
import { UserTypes } from "@/components/Login/utils";
import { CurrUserDetails } from "@/utils/currUserType";

interface SideBarHeaderProps {
    isSideBarOpen: boolean;
    locale: string;
    currentUser?: CurrUserDetails;
    selectedUserType?: number;
}

const SideBarHeader: React.FC<SideBarHeaderProps> = ({
    isSideBarOpen,
    locale,
    currentUser,
    selectedUserType,
}) => {
    const navigate = useNavigate();

    const getLogo = () => {
        if (locale === "en") {
            return isSideBarOpen ? LogoEnWithText : LogoEn;
        }
        if (locale === "ar") {
            return isSideBarOpen ? LogoArWithText : LogoAr;
        }
    };

    return (
        <div
            className={clsx("header-logo", "sidebar", {
                expanded: isSideBarOpen,
            })}
        >
            <img
                src={getLogo()}
                alt="logo"
                className="logo"
                onClick={() => {
                    if (!currentUser) {
                        if (selectedUserType === UserTypes.HP) {
                            window.open(
                                "https://www.withscalers.com/demand",
                                "_self",
                                "noreferrer"
                            );
                        } else {
                            window.open(
                                "https://www.withscalers.com/fast-track",
                                "_self",
                                "noreferrer"
                            );
                        }
                        return;
                    } else if (
                        needsToFillPlacement(currentUser) ||
                        bootcamperNeedToContinue(currentUser) ||
                        genericNeedsToPlacement(currentUser)
                    ) {
                        document.getElementById(
                            "you-need-to-complete-profile"
                        )!.style.display = "flex";
                    } else if (currentUser.name !== undefined) {
                        switch (currentUser.views) {
                            case "hp":
                                return navigate("/hp/candidates");
                            case "candidate":
                                return navigate("/candidate/jobs", {
                                    state: {
                                        source: "From Header",
                                    },
                                });
                            default:
                                return navigate(
                                    "/candidate/ft-vetting-process"
                                );
                        }
                    } else {
                        return navigate("/");
                    }
                }}
            />
        </div>
    );
};

export default SideBarHeader;
