import { Mixpanel, captureEvent } from "../Mixpanel";

export class ExperimentTracker {
  captureExperimentsGB = (experiment, result) => {
    captureEvent('$experiment_started', {
      'Experiment name' : experiment.key,
      'Variant name' : result.key,
      $source : 'growthbook'
    });
    Mixpanel.register({'experiment-variation' : `${experiment.key}-${result.key}`});
  };
}