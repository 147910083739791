import { captureEvent } from "../Mixpanel"
import { prefix_to_object } from "../utils";

export class JobChangeTracking {
    constructor(props) {
        this.source = props.source
    }

    closeJob = (job, reasons) => {
        captureEvent('Job Closed', {
            ...prefix_to_object(job, "job_"),
            reasons : reasons,
            source : this.source
        })
    }
    
    reopenJob = (job) => {
        captureEvent('Job Re-Opened', {
            ...prefix_to_object(job, "job_"),
            source : this.source
        })
    }

    deleteJob = (job, reasons) => {
        captureEvent('Job Deleted', {
            ...prefix_to_object(job, "job_"),
            source : this.source,
            reasons : reasons
        })
    }

}