import React from "react";
import "../../static/css/imgtitlesubtitle.css"

export default function ImgTitleSubtitle(props) {
    const { title, img, subtitle, className } = props

    return (
        <div className={`img-title-subtitle ${className ? className : ""}`}>
            <img src={img} />
            <div className="title-subtitle">
                <h3 className="title">{title}</h3>
                <div className="subtitle" dangerouslySetInnerHTML={{__html:subtitle}}></div>
            </div>
        </div>
    )
}