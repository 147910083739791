import { createContext, useContext } from "react";

export const TrackContext = createContext(null)

export function useTrackContext() {
    const context = useContext(TrackContext)
    if(!context) {
        throw new Error("No tracker")
    }
    return context
}

export const CloseModalContext = createContext(null)

export function useCloseModalContext() {
    const context = useContext(CloseModalContext)
    if(!context) {
        throw new Error("No tracker")
    }
    return context
}