import { useEffect } from "react";
import { Mixpanel, captureEvent, trackTime } from "../Mixpanel";
import {
    prefix_to_object,
    parse_query_params,
    free_trial_props,
    getSubscriptionDetails,
    getActiveVertical,
} from "../utils";
import { getUser } from "@/contexts/UserContext";

export class JobApplicantsTracking {
    constructor(props) {
        this.job = props.job;
        this.applicants = props.applicants;
        this.active = props.active;
        this.activeCandidateRef = props.activeCandidateRef;
        this.source = parse_query_params().source;
        this.currUser = getUser();
    }

    applicant_viewed = (response) => {
        captureEvent("Job Applicant Viewed", {
            ...prefix_to_object(response.candidate, "candidate_"),
            ...prefix_to_object(this.job, "job_"),
            application_created_at: response.created_at,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
        Mixpanel.people.increment("total_profiles_visited", 1);
        Mixpanel.people.increment("total_profiles_viewed_from_job", 1);
        trackTime("Job Applicant Viewed - Exited");
    };
    applicant_view_exited = (method) => {
        if (this.activeCandidateRef.current) {
            captureEvent("Job Applicant Viewed - Exited", {
                method_exited: method,
                ...prefix_to_object(
                    this.activeCandidateRef?.current,
                    "candidate_"
                ),
                ...prefix_to_object(this.job, "job_"),
                ...getSubscriptionDetails(this.currUser),
                ...getActiveVertical(this.vertical),
            });
        }
    };
    usePageExitHook = () => {
        useEffect(() => {
            const trackOnTabClose = (event) => {
                event.preventDefault();
                if (this.activeCandidateRef.current) {
                    this.applicant_view_exited("tab close");
                }
            };
            window.addEventListener("beforeunload", trackOnTabClose);
            return () => {
                this.applicant_view_exited("navigated away");
                window.removeEventListener("beforeunload", trackOnTabClose);
            };
        }, []);
    };
    decision = (given_decision, application, rejection_reasons) => {
        let eventName = `Job Applicant ${given_decision}`;
        captureEvent(eventName, {
            ...prefix_to_object(this.job, "job_"),
            ...prefix_to_object(this.activeCandidateRef?.current, "candidate_"),
            application_created_at: application.created_at,
            candidate_position: this.active + 1,
            ...(rejection_reasons && { rejection_reasons: rejection_reasons }),
            ...free_trial_props(this.currUser),
        });
        if (given_decision == "Shortlisted") {
            Mixpanel.people.increment("total_shortlist_count", 1);
            Mixpanel.people.increment("total_shortlist_from_job", 1);
        } else
            Mixpanel.people.increment("number_candidates_rejected_for_job", 1);
    };
    basic_tracking = (e, actionName) => {
        captureEvent(actionName, {
            source: "Job Applicants Page",
            ...prefix_to_object(this.activeCandidateRef?.current, "candidate_"),
            ...prefix_to_object(this.job, "job_"),
        });
        return false;
    };
    video_related_actions = (actionName, extraProps) => {
        captureEvent(actionName, {
            from: "Job Applicants Page",
            ...prefix_to_object(this.activeCandidateRef?.current, "candidate_"),
            ...prefix_to_object(this.job, "job_"),
            ...extraProps,
        });
    };
}
