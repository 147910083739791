import React, { useEffect, useRef } from "react";
import "./header-items-layout.css";
import { CloseOutlined, MenuOutlined } from "@mui/icons-material";

const HeaderItemsLayout = ({ locale, isMenuOpen, setIsMenuOpen, children }) => {
    const ref = useRef(null);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.querySelector(".menu-anchor-container").style.display =
                "grid";
        } else {
            document.querySelector(".menu-anchor-container").style.display =
                "none";
        }
    }, [isMenuOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    return (
        <>
            <div onClick={toggleMenu}>
                {<MenuOutlined className="primary-color" />}
            </div>
            <div className="menu-anchor-container">
                <div
                    className={`menu-anchor  ${locale === "en" ? "ltr-anchor" : ""}`}
                    ref={ref}
                >
                    <div
                        className={`head-container ${locale === "en" ? "text-align-right" : "text-align-left"}`}
                    >
                        <CloseOutlined
                            className="primary-color"
                            onClick={toggleMenu}
                            color={"var(--primary-500)"}
                        />
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default HeaderItemsLayout;
