import { RouteInterface } from "@/core/interfaces/route.interface";
import { lazy } from "react";
import {
    MessageOutlined,
    WorkOutlineOutlined,
    PersonOutline,
} from "@material-ui/icons";
import { BoltOutlined } from "@mui/icons-material";

const CMessages = lazy(() => import("@/pages/candidate/messages/Messages"));
const CProfile = lazy(() => import("@/pages/candidate/profile/Profile"));
const EditProfile = lazy(() => import("@/pages/candidate/profile/EditProfile"));
const NewPreviewPage = lazy(
    () => import("@/pages/candidate/profile/NewPreviewProfile")
);
const JobDetails = lazy(() => import("@/pages/candidate/jobs/JobDetails"));
const Jobs = lazy(() => import("@/pages/candidate/jobs/Jobs"));
const FakeJobDetails = lazy(() => import("@/pages/candidate/jobs/FakeJob"));
const FastTrack = lazy(() => import("@/pages/candidate/FastTrack"));
const VideoRecord = lazy(
    () => import("@/pages/candidate/fast-track/video-record/VideoRecord")
);
const PlacementForm = lazy(
    () => import("@/pages/candidate/fast-track/placement-form/PlacementForm")
);
const CompletePlacementForm = lazy(
    () =>
        import(
            "@/pages/candidate/fast-track/placement-form/CompletePlacementForm"
        )
);
const PlacementSuccess = lazy(
    () => import("@/pages/candidate/fast-track/placement-form/PlacementSuccess")
);
const ProfileCompleteSuccess = lazy(
    () =>
        import(
            "@/pages/candidate/fast-track/placement-form/ProfileCompleteSuccess"
        )
);
const BTCompletePlacement = lazy(
    () => import("@/pages/candidate/bootcamp/BTCompleteProfile")
);
const GenericPlacement = lazy(
    () => import("@/pages/candidate/generic-to-ft/PlacementForm/PlacementForm")
);

const routes: Array<RouteInterface> = [
    {
        path: "candidate/ft-vetting-process",
        element: FastTrack,
        isMenuItem: true,
        name: "fast_track.title",
        icon: BoltOutlined,
        props: {
            view: "fast-tracker",
        },
    },
    {
        path: "candidate/jobs",
        element: Jobs,
        isMenuItem: true,
        name: "jobs",
        icon: WorkOutlineOutlined,
        isPublic: true,
    },
    {
        path: "candidate/profile",
        element: CProfile,
        isMenuItem: true,
        name: "my_profile",
        icon: PersonOutline,
    },
    {
        path: "candidate/messages",
        element: CMessages,
        isMenuItem: true,
        name: "messages",
        icon: MessageOutlined,
    },
    {
        path: "candidate/profile/:specific_page",
        element: EditProfile,
    },
    {
        path: "candidate/profile_preview",
        element: NewPreviewPage,
    },
    {
        path: "candidate/jobs/:jobId",
        element: JobDetails,
        isPublic: true,
    },
    {
        path: "candidate/demo",
        element: FakeJobDetails,
    },
    {
        path: "candidate/video",
        element: VideoRecord,
    },
    {
        path: "candidate/placement",
        element: PlacementForm,
    },
    {
        path: "candidate/complete-placement",
        element: CompletePlacementForm,
    },
    {
        path: "candidate/placement-success",
        element: PlacementSuccess,
    },

    {
        path: "candidate/profile-complete-success",
        element: ProfileCompleteSuccess,
    },
    {
        path: "candidate/bt-placement",
        element: BTCompletePlacement,
    },
    {
        path: "candidate/generic-placement",
        element: GenericPlacement,
    },
];

const SalesCPRoutes = routes;
export default SalesCPRoutes;
