import React from "react";
import FormSelect from "../../components/ControlledFields/FormSelect";
import { CancelOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import MyRadioGroup from "../../components/ControlledFields/MyRadioGroup";
import {
  englishProfChoices,
  monthsExperienceChoices,
  salaryExpectationsChoices,
  jobTypeChoices,
} from "./jobformutils";
import axiosInstance from "../../../static/axios";

const Filter = (props) => {
  return (
    <div className="job-filter">
      <p className="title">{props.title}</p>
      <div className="component-and-remove">
        {props.component}
        <IconButton onClick={props.removeFilter}>
          <CancelOutlined />
        </IconButton>
      </div>
    </div>
  );
};

export const getMatchingFromBackend = (filters, setValue) => {
  if (filters.length > 0) {
    axiosInstance.post("/job/get_matching/", filters).then((res) => {
      setValue(Math.round(res.data.percent));
    });
    return;
  }
  setValue(100);
};

const onChangeFilters = (
  functionToDo,
  action,
  filters,
  setValue,
  index,
  field,
  track
) => {
  getMatchingFromBackend(ignoreEmptyFilters(filters), setValue);
  if (action == "clear") {
    track.filter_removed("Clear", filters, index);
  } else if (action == "removeOption") {
    track.filter_removed("Specific", filters, index, field);
  } else {
    track.filter_added("Specific", filters, index, undefined, field);
  }
  functionToDo;
};

export const ignoreEmptyFilters = (filters) => {
  let new_filters = [];
  for (let i = 0; i < filters.length; i++) {
    if (Array.isArray(filters[i].value)) {
      if (filters[i].value.length !== 0) new_filters.push(filters[i]);
    } else {
      if (filters[i].value && filters[i].value !== null)
        new_filters.push(filters[i]);
    }
  }
  return new_filters;
};

export const CityFilter = (props) => {
  return (
    <Filter
      title={props.t("city")}
      component={
        <FormSelect
          control={props.control}
          items={props.cities}
          name={`filters[${props.index}].value`}
          isRequired={true}
          multi={true}
          placeholder={props.t("select")}
          onChange={props.onChange}
        />
      }
      removeFilter={props.onRemove}
    />
  );
};

export const ExperienceFilter = (props) => {
  return (
    <Filter
      title={props.t("job_form.experience")}
      component={
        <FormSelect
          control={props.control}
          items={monthsExperienceChoices}
          name={`filters[${props.index}].value`}
          isRequired={true}
          label={props.t("select")}
          placeholder={props.t("select")}
          onChange={props.onChange}
        />
      }
      removeFilter={props.onRemove}
    />
  );
};

export const EnglishProfFilter = (props) => {
  return (
    <Filter
      title={props.t("english_proficiency.title")}
      component={
        <MyRadioGroup
          onChange={props.onChange}
          options={englishProfChoices}
          control={props.control}
          valueName={`filters[${props.index}].value`}
        />
      }
      removeFilter={props.onRemove}
    />
  );
};

export const MajorFilter = (props) => {
  return (
    <Filter
      title={props.t("major")}
      component={
        <FormSelect
          control={props.control}
          items={props.major}
          name={`filters[${props.index}].value`}
          onChange={props.onChange}
          isRequired={true}
          multi={true}
          placeholder={props.t("select")}
        />
      }
      removeFilter={props.onRemove}
    />
  );
};

export const SalaryExpectationFilter = (props) => {
  return (
    <Filter
      title={props.t("job_form.salary")}
      component={
        <FormSelect
          control={props.control}
          items={salaryExpectationsChoices}
          name={`filters[${props.index}].value`}
          label={props.t("select")}
          isRequired={true}
          placeholder={props.t("select")}
          onChange={props.onChange}
        />
      }
      removeFilter={props.onRemove}
    />
  );
};

export const UniversityFilter = (props) => {
  return (
    <Filter
      title={props.t("university")}
      component={
        <FormSelect
          control={props.control}
          items={props.universities}
          name={`filters[${props.index}].value`}
          isRequired={true}
          multi={true}
          placeholder={props.t("select")}
          onChange={props.onChange}
        />
      }
      removeFilter={props.onRemove}
    />
  );
};

export const JobTypeFilter = (props) => {
  return (
    <Filter
      title={props.t("job_type")}
      component={
        <MyRadioGroup
          onChange={props.onChange}
          control={props.control}
          options={jobTypeChoices}
          valueName={`filters[${props.index}].value`}
        />
      }
      removeFilter={props.onRemove}
    />
  );
};

export const ListFilters = (
  control,
  t,
  onRemove,
  index,
  options,
  getValues,
  setValue,
  track
) => {
  const onChange = (func, action, field) =>
    onChangeFilters(
      func,
      action,
      getValues.filters,
      setValue,
      index,
      field,
      track
    );

  return {
    citieslookup_id: (
      <CityFilter
        control={control}
        t={t}
        onRemove={onRemove}
        index={index}
        cities={options.cities}
        onChange={onChange}
      />
    ),
    months_of_experience: (
      <ExperienceFilter
        control={control}
        t={t}
        onRemove={onRemove}
        index={index}
        onChange={onChange}
      />
    ),
    english_proficiency_id: (
      <EnglishProfFilter
        control={control}
        t={t}
        onRemove={onRemove}
        index={index}
        onChange={onChange}
      />
    ),
    major_id: (
      <MajorFilter
        control={control}
        t={t}
        onRemove={onRemove}
        index={index}
        major={options.majors}
        onChange={onChange}
      />
    ),
    salary_expectations_id: (
      <SalaryExpectationFilter
        control={control}
        t={t}
        onRemove={onRemove}
        index={index}
        onChange={onChange}
      />
    ),
    university_id: (
      <UniversityFilter
        control={control}
        t={t}
        onRemove={onRemove}
        index={index}
        universities={options.universities}
        onChange={onChange}
      />
    ),
    jobtypeslookup_id: (
      <JobTypeFilter
        control={control}
        t={t}
        onRemove={onRemove}
        index={index}
        onChange={onChange}
      />
    ),
  };
};

const fieldsContainFilter = (filters, filter) => {
  for (let i = 0; i < filters.length; i++) {
    if (filters[i].name == filter) {
      return true;
    }
  }
  return false;
};

export const allJobFilters = (fields, t) => {
  let filters = [
    {
      label: "city",
      name: "citieslookup_id",
    },
    {
      label: "months_of_experience",
      name: "months_of_experience",
    },
    {
      label: "english_proficiency.title",
      name: "english_proficiency_id",
    },
    {
      label: "major",
      name: "major_id",
    },
    {
      label: "salary_expectations",
      name: "salary_expectations_id",
    },
    {
      label: "university",
      name: "university_id",
    },
    {
      label: "job_type",
      name: "jobtypeslookup_id",
    },
  ];
  let arr = filters
    .filter((filter) => {
      if (fieldsContainFilter(fields, filter.name)) {
        return false;
      }
      return true;
    })
    .map((filter) => {
      return {
        label: t(filter.label),
        name: filter.name,
        checked: false,
      };
    });

  return arr;
};

export const getDefaultValues = (name) => {
  switch (name) {
    case "citieslookup_id":
    case "university_id":
    case "major_id":
      return [];
    case "months_of_experience":
    case "salary_expectations_id":
      return null;
    case "jobtypeslookup_id":
      return jobTypeChoices[0];
    case "english_proficiency_id":
      return englishProfChoices[0];
  }
};

export const addFilter = (append, filter, track) => {
  append({
    name: filter.name,
    value: getDefaultValues(filter.name),
  });
  track.filter_added("Whole", undefined, undefined, filter);
};

export const removeFilter = (remove, index, filters, track) => {
  remove(index);
  track.filter_removed("Filter Deleted", filters, index);
};
