import { getChangedData } from "../utils";
import { prefix_to_object, companyUnedited, currUserUnedited } from "../utils";
import { captureEvent } from "../Mixpanel";
import { getUser } from "../../contexts/UserContext";

export class SettingsTracker {
    constructor(props) {
        this.props = props;
        this.currUser = getUser();
    }
    personal_info_update = () => {
        captureEvent(
            "Personal Information Updated",
            getChangedData(this.props.formData, currUserUnedited(this.currUser))
        );
    };
    password_changed = () => {
        captureEvent("Password Changed", {
            from_page: "Account Details",
        });
    };
    company_info_update = () => {
        captureEvent(
            "Company Information Updated",
            getChangedData(
                this.props.formData,
                companyUnedited(this.currUser.company)
            )
        );
    };
    add_employee_button_click = () => {
        captureEvent("Add Employee Clicked");
    };
    employee_activate = (employee) => {
        captureEvent("Employee Activated", {
            ...prefix_to_object(employee, "activated_employee_"),
        });
    };
    employee_deactivate = (employee) => {
        captureEvent("Employee De-activated", {
            ...prefix_to_object(employee, "deactivated_employee_"),
        });
    };
    legal_document_deleted = () => {
        captureEvent("Legal Document Deleted", {
            document_name: this.props.doc_id,
        });
    };
    document_uploaded = () => {
        captureEvent(
            this.props.doc_id == "logo"
                ? "Company Logo Uploaded"
                : "Legal Document Uploaded",
            {
                document_name: this.props.doc_id,
            }
        );
    };
    employee_invited = (email) => {
        captureEvent("Employee Invited", {
            email_invited: email,
        });
    };
    tab_viewed = (tabName, data) => {
        captureEvent("Settings Tab Viewed", {
            tab_name: tabName,
            ...data,
        });
    };
    subscription_call = (action, data) => {
        captureEvent("Subscription Call", {
            action: action,
            ...data,
        });
    };
    company_information_modal_start = (data) => {
        captureEvent("Company Information Modal Started", { ...data });
    };

    company_information_modal_end = (data) => {
        captureEvent("Company Information Modal Ended", { ...data });
    };

    company_confirmation_modal = (data) => {
        captureEvent("Company Confirmation Confirmed", { ...data });
    };
}
