import React from "react";
import clsx from "clsx";
import { Divider } from "@mui/material";
import NewUserProfileCard from "@/components/Header/components/user-profile-card/NewUserProfileCard";
import SidebarButton from "../components/sidebar-button/sidebar-button";
import LanguageButton from "../components/platform-actions/language-button";
import SidebarLoginButton from "../components/sidebar-button/sidebar-login-button";
import SubscriptionLabel from "@/components/SubscriptionLabel";
import { useTranslation } from "react-i18next";
import { CurrUserDetails } from "@/utils/currUserType";

interface SideBarFooterProps {
    isSideBarOpen: boolean;
    setSideBarVisibility: (visibility: boolean) => void;
    currentUser: CurrUserDetails;
    locale: string;
    changeLang: (lang: string) => void;
}

const SideBarFooter: React.FC<SideBarFooterProps> = ({
    isSideBarOpen,
    setSideBarVisibility,
    currentUser,
    locale,
    changeLang,
}) => {
    const { t } = useTranslation();

    const logout = () => {
        document.getElementById("logout-modal")!.style.display = "flex";
    };

    const contact = () => {
        document.getElementById("contact-modal")!.style.display = "flex";
    };

    return (
        <>
            <Divider
                sx={{
                    marginInline: "16px",
                    marginTop: "32px",
                }}
            />
            <div
                className={clsx("sidebar sidebar--default", {
                    "sidebar--default--expanded": isSideBarOpen,
                })}
            >
                <SidebarButton
                    setSideBarVisibility={setSideBarVisibility}
                    isSideBarOpen={isSideBarOpen}
                />
                <LanguageButton
                    changeLang={changeLang}
                    isExpended={isSideBarOpen}
                />
                {currentUser.name ? (
                    <NewUserProfileCard
                        isExpanded={isSideBarOpen}
                        user={currentUser}
                        locale={locale}
                        logout={logout}
                        contact={contact}
                    />
                ) : (
                    <SidebarLoginButton isSideBarOpen={isSideBarOpen} />
                )}
                {currentUser?.views === "hp" && (
                    <SubscriptionLabel
                        currUser={currentUser}
                        t={t}
                        mobile={false}
                        clickable={true}
                        isExpanded={isSideBarOpen}
                        isSideBar={true}
                    />
                )}
            </div>
        </>
    );
};

export default SideBarFooter;
