import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // dependent on utc plugin
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone';
import {Mixpanel} from "../mixpanel-tracking/Mixpanel";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault('Asia/Karachi');

// Helper function to calculate the time left until the target date
export const calculateTimeLeft = (targetDate) => {
    const yearAppended = targetDate + ' ' + dayjs().year();
    const difference = dayjs(yearAppended).diff(dayjs(), 'millisecond', true);
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: dayjs(difference).format('HH'),
            minutes: dayjs(difference).format('mm'),
            seconds: dayjs(difference).format('ss'),
        };
    }

    return timeLeft;
};

export const calculateDaysLeft = (seconds) => {
    if (!seconds || seconds < 0) {
        return 0
    }
    const hourInSeconds = 60 * 60
    const dayInSeconds = hourInSeconds * 24
    return seconds < hourInSeconds ? "<1" : Math.ceil(seconds / dayInSeconds)
}

export function captureEvent(event_name, props) {
    Mixpanel.track(event_name, props);
}

export function trackTime(until_event_name) {
    Mixpanel.time_event(until_event_name);
}

export function capturePageViewEvent(event_name, trackOnTabClose, extraProps) {
    const page_exited_postfix = ' - Page Exited';
    captureEvent(event_name, {
        url: window.location.pathname,
        'page name': document.title,
        ...extraProps
    });
    trackTime(event_name + page_exited_postfix);
    window.addEventListener('beforeunload', trackOnTabClose);
}