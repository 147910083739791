import { calculateDaysLeft } from "@/helpers/helpers";
import { getObjectDiff } from "../utils/utilFunctions";
import Verticals from "@/core/enums/verticals.enum";

export const currUserUnedited = (currUser) => {
    return (({
        name,
        position,
        other_position,
        location,
        gender,
        job_type,
        nationality,
        phone,
        linkedin,
    }) => ({
        name,
        position,
        other_position,
        location,
        gender,
        job_type,
        nationality,
        phone,
        linkedin,
    }))(currUser);
};

export const companyUnedited = (company) => {
    return (({
        name,
        industry,
        size,
        hq_location,
        description,
        twitter,
        website,
        hiring_cities,
        linkedin,
    }) => ({
        name,
        industry,
        size,
        hq_location,
        description,
        twitter,
        website,
        hiring_cities,
        linkedin,
    }))(company);
};
const keysToSanitize = ["access", "refresh", "reset_link"];
export const prefix_to_object = (obj, prefix) => {
    return Object.keys(obj).reduce((a, c) => {
        if (keysToSanitize.includes(c)) return a;
        if (c === "created_source") return (a[c] = obj[c]), a;
        return (a[`${prefix}${c}`] = obj[c]), a;
    }, {});
};

export const prefix_to_object_from_array = (array, prefix) => {
    return array.reduce((a, c) => ((a[`${prefix}${c.name}`] = c.value), a), {});
};

export const find_missing_prop = (arr1, arr2) => {
    const results = arr1.filter(
        ({ id: id1 }) => !arr2.some(({ id: id2 }) => id2 === id1)
    );
    return results;
};

export const combine_old_and_changes = (changes, data, user) => {
    let combination = {};
    Object.keys(changes).forEach(function func(item) {
        combination[`${item}_from`] = user[item];
        combination[`${item}_to`] = data[item];
    });
    return combination;
};

export const getChangedData = (newData, oldData) => {
    let allChangedData = {};
    let changedItems = getObjectDiff(newData, oldData);

    changedItems.forEach(function func(item) {
        allChangedData[`${item}_from`] = oldData[item];
        allChangedData[`${item}_to`] = newData[item];
    });
    return allChangedData;
};

export const checkCityMajorUni = (currUser, formData) => {
    let changed = {};
    if (currUser.city.id) {
        currUser["city"] = currUser.city.id;
        currUser["major"] = currUser.major.id;
        currUser["university"] = currUser.university?.id || null;
    }
    if (currUser.city !== formData.city) {
        changed["city_from"] = currUser.city;
        changed["city_to"] = formData.city;
    }
    if (currUser.major !== formData.major) {
        changed["major_from"] = currUser.major;
        changed["major_to"] = formData.major;
    }
    if (currUser.university !== formData.university) {
        changed["university_from"] = currUser.university;
        changed["university_to"] = formData.university;
    }
    return changed;
};

export const checkSalary = (currUser, formData) => {
    if (currUser.salary_expectations.id) {
        currUser["salary_expectations"] = currUser.salary_expectations.id;
    }
    if (currUser.salary_expectations !== formData.salary_expectations) {
        return {
            salary_expectations_from: currUser.salary_expectations,
            salary_expectations_to: formData.salary_expectations,
        };
    }
    return {};
};

export const jobPreferencesUnedited = (currUser) => {
    return (({
        salary_expectations,
        remote_work,
        field_work,
        job_type,
        cities_to_relocate,
        top_industries,
    }) => ({
        salary_expectations,
        remote_work,
        field_work,
        job_type,
        cities_to_relocate,
        top_industries,
    }))(currUser);
};

export const parse_query_params = (() => {
    const cache = {};

    return function () {
        const key = window.location.search + window.location.hash;
        // Check if the result is already memoized
        const cachedResult = cache[key];
        if (cachedResult) {
            return cachedResult;
        }
        // TODO: Find an alternative for this, currently WhatsApp links are parsing like this
        const search = key.replace(/&amp;/g, "&").replace(/&#x3D;/g, "=");
        const searchParams = new URLSearchParams(search);
        const paramsDict = {};
        searchParams.forEach((value, key) => {
            paramsDict[key] = decodeURIComponent(value);
        });

        // Memoize the result
        cache[key] = paramsDict;

        return paramsDict;
    };
})();

export const parse_from_path = (from) => {
    const paramsDict = {};
    if (from) {
        const searchParams = new URLSearchParams(from);
        searchParams.forEach((value, key) => {
            paramsDict[key] = decodeURIComponent(value);
        });
    }
    return paramsDict;
};

export const free_trial_props = (currUser) => {
    let free_trial = currUser.company.free_trial;
    if (currUser.views === "hp") {
        return {
            free_trial: free_trial,
            ...(free_trial && {
                days_left: calculateDaysLeft(
                    currUser.company.access_time_remaining
                ),
            }),
        };
    }
    return {};
};

export const getSubscriptionDetails = (currUser) => {
    return {
        subscription_tier: currUser.company?.active_subscription.tier,
        subscription_verticals: currUser.company?.active_subscription.verticals,
    };
};

export const getActiveVertical = (vertical) => {
    let verticalName;
    switch (vertical) {
        case Verticals.Marketing:
            verticalName = "Marketing";
            break;
        case Verticals.Sales:
            verticalName = "Sales";
            break;
        case Verticals.Accounting:
            verticalName = "Accouting";
            break;
        default:
            verticalName = "Vertical must be added manually";
    }
    return {
        vertical: verticalName,
    };
};
