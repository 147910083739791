import React from "react";
import "../../../static/css/reasonremovemodal.css";
import { LoadingButton } from "@mui/lab";

function HPOnBoardingFeedbackModal(props) {
  
  const { action, message, heading, actionText, modalLogo, logoClass } = props;
  
  return (
    <>
      <div
        id="incomplete-onbaording-message"
        className="reason-remove-modal modal"
        style={{display:"flex"}}
      >
        <div
          id={`incomplete-onbaording-message-content`}
          className="modal-content"
          onClick={(e) => {
            e.stopPropagation();
            return;
          }}
        >
          <div className="infoWrapper">
            <span className={logoClass}>
              {modalLogo}
            </span>
            {heading &&
              <div className="modal-heading"> {heading}</div>
            }
            <p className="infoWrapper__description">
              {message}
            </p>
            
            {actionText &&
              <div className="infoWrapper__btnWrapper">
                <LoadingButton className="filled-button" onClick={action}>{actionText}</LoadingButton>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default HPOnBoardingFeedbackModal;
