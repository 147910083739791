import React, { createContext, useContext, useState, ReactNode } from "react";

interface BackgroundContextProps {
    backgroundClass: string;
    setBackgroundClass: React.Dispatch<React.SetStateAction<string>>;
}

const BackgroundContext = createContext<BackgroundContextProps | undefined>(
    undefined
);

export const useBackground = (): BackgroundContextProps => {
    const context = useContext(BackgroundContext);
    if (!context) {
        throw new Error(
            "useBackground must be used within a BackgroundProvider"
        );
    }
    return context;
};

interface BackgroundProviderProps {
    children: ReactNode;
}

export const BackgroundProvider: React.FC<BackgroundProviderProps> = ({
    children,
}) => {
    const [backgroundClass, setBackgroundClass] =
        useState<string>("default-background");

    return (
        <BackgroundContext.Provider
            value={{ backgroundClass, setBackgroundClass }}
        >
            <div className={backgroundClass}>{children}</div>
        </BackgroundContext.Provider>
    );
};
