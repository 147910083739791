import React, { createContext, useContext, useEffect } from 'react';
import {Factory, Pages} from "@/mixpanel-tracking/hp/factory";

const EmailVerificationContext = createContext();

export const useEmailVerification = () => {
  return useContext(EmailVerificationContext);
};

export const EmailVerificationProvider = ({ children }) => {
  
  const channel = new BroadcastChannel('email_verification_channel');
  const factory = new Factory()
  let track = factory.get_tracker(Pages.EXTRA_TRACKER)
  
  useEffect(() => {
    channel.onmessage = (message) => {
      if (message.data.verified) {
        track.email_verify()
      }
    };
    
    return () => {
      channel.close();
    };
  }, []);
  
  const email_verify = () => {
    channel.postMessage({ verified: true });
    track.email_verify()
  };
  
  return (
    <EmailVerificationContext.Provider value={{ email_verify }}>
      {children}
    </EmailVerificationContext.Provider>
  );
};
