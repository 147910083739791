import { useState } from "react";
import close_img from "../../../static/images/close.png";
import "../../../static/css/modal.scss";
import { TextareaAutosize } from "@mui/material";
import React from "react";
import axiosInstance from "../../../static/axios";
import FeedbackSentModal from "./FeedbackSentModal";
import ErrorModal from "./ErrorModal";
import { useTranslation } from "react-i18next";
import { getUser } from "@/contexts/UserContext";

function FeedbackModal() {
    const { t } = useTranslation();
    const currUser = getUser();
    // State and setState for request to be sent
    const [formData, setFormData] = useState({
        content: "",
    });
    // Handle form input change
    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    // Handle form submit
    const onSubmit = (e) => {
        e.preventDefault();
        if (formData.content == "") {
            document.getElementById("empty-feedback").style.display = "flex";
        } else {
            axiosInstance
                .post("user/feedback/", {
                    ...formData,
                    user: currUser.id,
                })
                .then(() => {
                    document.getElementById(
                        "feedback-success-modal"
                    ).style.display = "flex";
                })
                .catch(() => {
                    document.getElementById("empty-feedback").style.display =
                        "flex";
                });
        }
        document.getElementById("contact-modal").style.display = "none";
        setFormData({
            user: formData.user,
            content: "",
        });
    };

    const closeModal = () => {
        setFormData({
            user: formData.user,
            content: "",
        });
        document.getElementById("contact-modal").style.display = "none";
    };

    return (
        <>
            <div
                id="contact-modal"
                className="modal"
                style={{ display: "none" }}
                onClick={closeModal}
            >
                <div
                    id="contact-modal-content"
                    className="modal-content"
                    onClick={(e) => {
                        e.stopPropagation();
                        return;
                    }}
                >
                    <h4>{t("contact_us.main")}</h4>
                    <TextareaAutosize
                        maxLength={500}
                        name="content"
                        placeholder={t("contact_us.placeholder")}
                        value={formData.content}
                        onChange={(e) => onChange(e)}
                        maxRows={5}
                    />
                    <button className="filled-button" onClick={onSubmit}>
                        {t("submit")}
                    </button>
                    <img
                        src={close_img}
                        className="modal-close"
                        onClick={closeModal}
                    />
                </div>
            </div>
            <ErrorModal
                type="empty-feedback"
                message={t("contact_us.error")}
                t={t}
            />
            <FeedbackSentModal t={t} />
        </>
    );
}

export default FeedbackModal;
