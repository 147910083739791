import axios from "axios";
import singletonLocalStorage from "../src/utils/localStoring";
import {
    checkGenericNotificationAllowedUrls,
    clearSpecificsFromLocal,
} from "../src/utils/utilFunctions";
import {
    setGeneralSnackbar,
    setGeneralSnackbarInitial,
} from "@/core/stores/general/general.store";
import store from "@/core/stores";
import i18next from "i18next";

const baseURL = import.meta.env.VITE_BASE_URL;

const refresh = axios.create({
    baseURL: baseURL,
});

const handleGenericErrorNotification = () => {
    store.dispatch(
        setGeneralSnackbar({
            showing: true,
            type: "error",
            variant: "outlined",
            hideDuration: 3000,
            relativeHorizontal: "start",
            vertical: "top",
            message: i18next.t("generic_error"),
            showIcon: true,
        })
    );
    setTimeout(() => {
        store.dispatch(setGeneralSnackbarInitial());
    }, 3000);
};

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 10000,
    headers: {
        Authorization: singletonLocalStorage.getItem("access_token")
            ? "JWT " + singletonLocalStorage.getItem("access_token")
            : null,
        "Content-Type": "application/json",
        accept: "application/json",
    },
});

axiosInstance.defaults.xsrfCookieName = "csrftoken";
axiosInstance.defaults.xsrfHeaderName = "X-CSRFToken";

axiosInstance.interceptors.request.use(
    (config) => {
        const token = singletonLocalStorage.getItem("access_token");
        if (token) {
            config.headers["Authorization"] = "JWT " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (
            (err.response.status === 400 || err.response.status === 500) &&
            // this check is for only particular routes to be checked against generic notification error
            checkGenericNotificationAllowedUrls(originalConfig.url)
        ) {
            handleGenericErrorNotification();
            return Promise.reject(err);
        }
        if (
            err.response &&
            ![
                "/user/login/",
                "/user/employee/login/",
                "/user/otp/employee_email_login/",
            ].includes(originalConfig.url)
        ) {
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const rs = await refresh.post(
                        "/user/token/refresh/",
                        {
                            refresh:
                                singletonLocalStorage.getItem("refresh_token"),
                        },
                        {
                            headers: {
                                Authorization: singletonLocalStorage.getItem(
                                    "access_token"
                                )
                                    ? "JWT " +
                                      singletonLocalStorage.getItem(
                                          "access_token"
                                      )
                                    : null,
                                "Content-Type": "application/json",
                                accept: "application/json",
                            },
                        }
                    );
                    const accessToken = rs.data.access;
                    singletonLocalStorage.setItem("access_token", accessToken);
                    axiosInstance.defaults.headers.common["Authorization"] =
                        "JWT " + accessToken;
                    return axiosInstance(originalConfig);
                } catch (_error) {
                    clearSpecificsFromLocal();

                    window.location.href =
                        "" +
                        `${window.location.protocol}//${window.location.host}/?reason=token-expiry`;
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    }
);

export default axiosInstance;
