import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./new-user-profile-card.scss";
import {
    SettingsOutlined,
    ContactSupportOutlined,
    ExitToApp,
} from "@material-ui/icons";
import MenuLink from "../../components/menu-link/menu-link";
import i18n from "@/i18n";
import { isOnboarding } from "@/utils/utilFunctions";
import { Button } from "@mui/material";
import { getRandomColor } from "../../utils";
import ScalersPopover from "@/components/atoms/popover/popover";
import {CurrUserDetails} from "@/utils/currUserType";
import {LoginOutlined} from "@mui/icons-material";
import ScalersButton from "@/components/atoms/button/button";

interface UserProfileCardProps {
    isExpanded: boolean;
    locale: string;
    user: CurrUserDetails;
    logout: () => void;
    contact: () => void;
}

const NewUserProfileCard: React.FC<UserProfileCardProps> = ({
    isExpanded,
    locale,
    user,
    logout,
    contact,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { t } = useTranslation();
    const dir = i18n.dir();

    const [altColor] = React.useState(getRandomColor());

    const getFirstTwoCharFromEachWord = (str: string): string => {
        const words = str.split(" ");
        const firstChars = words.map((word) => word.charAt(0));
        return firstChars.join("").substring(0, 2);
    };

    const openMenu = (): void => {
        setIsMenuOpen(true);
    };
    const closeMenu = (): void => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent): void => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    return (
        <div className="user-profile-card" onClick={openMenu} ref={ref}>
            {user.name && (
                <>
                    {user.picture ? (
                        <img
                            className="image"
                            src={user.picture}
                            alt={
                                user.name &&
                                getFirstTwoCharFromEachWord(
                                    dir === "ltr" && user.name_eng
                                        ? user.name_eng
                                        : user.name
                                )
                            }
                            style={{ backgroundColor: altColor }}
                        />
                    ) : (
                        <div
                            className="image"
                            style={{ backgroundColor: altColor }}
                        >
                            {user.name &&
                                getFirstTwoCharFromEachWord(
                                    dir === "ltr" && user.name_eng
                                        ? user.name_eng
                                        : user.name
                                )}
                        </div>
                    )}

                    {isExpanded && (
                        <div className="info">
                            {t("Hello")}
                            <br />
                            <span className="name">
                                {dir === "ltr" && user.name_eng
                                    ? user.name_eng
                                    : user.name}
                            </span>
                        </div>
                    )}
                    <ScalersPopover
                        name="profile-setting-popup"
                        open={isMenuOpen}
                        anchorEl={ref.current}
                        handleClose={closeMenu}
                        transformVert="top"
                    >
                        {user.views === "hp" && (
                            <MenuLink
                                text={t("settings")}
                                to="/hp/settings"
                                isDisabled={isOnboarding(user)}
                            >
                                <SettingsOutlined />
                            </MenuLink>
                        )}
                        <Button
                            className="menu-link"
                            onClick={contact}
                            startIcon={<ContactSupportOutlined />}
                        >
                            {t("contact_us.title")}
                        </Button>
                        <Button
                            className="menu-link menu-link--danger text-button-danger"
                            onClick={logout}
                            startIcon={<ExitToApp />}
                        >
                            {t("logout.title")}
                        </Button>
                    </ScalersPopover>
                </>
            )}
        </div>
    );
};

export default NewUserProfileCard;
