import React, { useEffect } from "react";
import { useBackground } from "@/contexts/BackgroundContext";

const withBodyClass = (
    Component: React.ComponentType<any>,
    bodyClass: string
): React.FC<any> => {
    return (props) => {
        const { setBackgroundClass } = useBackground();

        useEffect(() => {
            document.body.classList.add(bodyClass);
            setBackgroundClass(bodyClass);

            return () => {
                document.body.classList.remove(bodyClass);
            };
        }, [bodyClass, setBackgroundClass]);

        return <Component {...props} />;
    };
};

withBodyClass.displayName = "withBodyClass";

export default withBodyClass;
