import React from "react";
import { CloseModalContext, TrackContext } from "./Context";
import ModalSingleMainAction from "./ModalSingleMainAction";
import ModalSubtitleDetails from "./ModalSubtitleDetails";
import ModalJobApplicantsNumber from "./ModalJobApplicantsNumber";
import "../../../static/css/modal.scss";
import ModalXButton from "./ModalXButton";
import ModalTwoActions from "./ModalTwoActions";
import ModalTitle from "./ModalTitle";
import CancelAction from "./ModalSingleSecondaryAction";
import ModalIllustration from "./ModalIllustration";
import ModalSubSubtitle from "./ModalSubSubtitle";
import ModalTopTitle from "./ModalTopTitle";
import clsx from "clsx";

export default function Modal(props) {
    const {
        type,
        children,
        track,
        closable = true,
        isBgBlurred = false,
        closeModalActions = function () {},
    } = props;

    const closeModal = () => {
        document.getElementById(type).style.display = "none";
        closeModalActions();
    };

    return (
        <TrackContext.Provider value={{ track }}>
            <CloseModalContext.Provider value={{ closeModal }}>
                <div
                    id={type}
                    data-testid={`Modal-${type}`}
                    className={clsx("modal", "bottom-sheet", {
                        "bg-blurred": isBgBlurred,
                    })}
                    onClick={closable ? closeModal : () => {}}
                >
                    <div
                        className="modal-content"
                        onClick={(e) => {
                            if (closable) {
                                e.stopPropagation();
                                return;
                            }
                        }}
                    >
                        {children}
                    </div>
                </div>
            </CloseModalContext.Provider>
        </TrackContext.Provider>
    );
}

Modal.Title = ModalTitle;
Modal.TopTitle = ModalTopTitle;
Modal.TwoActions = ModalTwoActions;
Modal.SubtitleDetails = ModalSubtitleDetails;
Modal.SingleMainAction = ModalSingleMainAction;
Modal.CancelAction = CancelAction;
Modal.XButton = ModalXButton;
Modal.JobApplicantsNumber = ModalJobApplicantsNumber;
Modal.Illustration = ModalIllustration;
Modal.SubSubtitle = ModalSubSubtitle;
