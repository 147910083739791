import * as yup from "yup";

export const jobTypeChoices = [
    {
        id: 2,
        name_en: "Full-time",
        name_ar: "دوام كامل",
    },
    {
        id: 1,
        name_en: "Part-time",
        name_ar: "دوام جزئي",
    },
    {
        id: 3,
        name_en: "Internship",
        name_ar: "تدريب",
    },
];

export const workPrefChoices = [
    {
        id: 1,
        name_en: "On-Site",
        name_ar: "حضوري",
    },
    {
        id: 2,
        name_en: "Remote",
        name_ar: "عن بعد",
    },
    {
        id: 3,
        name_en: "Hybrid",
        name_ar: "هجين (حضور و عن بعد)",
    },
];

export const monthsExperienceChoices = [
    {
        id: 6,
        name_en: "6 months or more",
        name_ar: "6 أشهر وأكثر",
    },
    {
        id: 12,
        name_en: "1 year or more",
        name_ar: "سنة وأكثر",
    },
    {
        id: 24,
        name_en: "2 years or more",
        name_ar: "سنتان وأكثر",
    },
    {
        id: 36,
        name_en: "3 years or more",
        name_ar: "3 سنوات وأكثر",
    },
    {
        id: 48,
        name_en: "4 years or more",
        name_ar: "4 سنوات وأكثر",
    },
    {
        id: 60,
        name_en: "5 years or more",
        name_ar: "5 سنوات وأكثر",
    },
];

export const salaryExpectationsChoices = [
    {
        id: 1,
        name_en: "6 K or less",
        name_ar: "6 ألاف وأقل",
    },
    {
        id: 2,
        name_en: "7.5 K or less",
        name_ar: "7.5 ألاف وأقل",
    },
    {
        id: 3,
        name_en: "8.5 K or less",
        name_ar: "8.5 ألاف وأقل",
    },
    {
        id: 4,
        name_en: "9.5 K or less",
        name_ar: "9.5 ألاف وأقل",
    },
    {
        id: 6,
        name_en: "12 K or less",
        name_ar: "12 الآف وأقل",
    },
];

export const englishProfChoices = [
    {
        id: 3,
        name_en: "Beginner or higher",
        name_ar: "مبتدئ فأعلى",
    },
    {
        id: 2,
        name_en: "Intermediate or higher",
        name_ar: "متوسط فأعلى",
    },
    {
        id: 1,
        name_en: "Advanced",
        name_ar: "متقدم",
    },
];

export const schema = (t) => {
    return [
        yup.object(),
        yup
            .object()
            .shape({
                title: yup
                    .string()
                    .required(t("validation.required"))
                    .min(5, t("validation.minLength", { length: 5 }))
                    .max(80),
                number_vacancies: yup
                    .number()
                    .required(t("validation.required"))
                    .min(1, t("validation.minLength", { length: 1 })),
                description: yup.object(),
                type: yup
                    .object()
                    .shape({
                        name: yup.string(),
                        value: yup
                            .number()
                            .min(1, t("validation.minLength", { length: 1 })),
                    })
                    .required(t("validation.required")),
                location: yup
                    .object()
                    .shape({
                        name: yup.string(),
                        value: yup
                            .number()
                            .min(1, t("validation.minLength", { length: 1 })),
                    })
                    .required(t("validation.required")),
                workplace_preference: yup
                    .object()
                    .shape({
                        name: yup.string(),
                        value: yup
                            .number()
                            .min(1, t("validation.minLength", { length: 1 })),
                    })
                    .required(t("validation.required")),
                salary_range: yup
                    .array()
                    .of(yup.number().min(1500).max(12000))
                    .required(t("validation.required")),
                open_for_discussion: yup.boolean(),
                commission_based: yup.boolean(),
                benefits: yup.array().of(
                    yup.object().shape({
                        info: yup.object().shape({
                            id: yup.number(),
                            name_en: yup.string(),
                            name_ar: yup.string(),
                        }),
                        checked: yup.boolean(),
                    })
                ),
            })
            .required(t("validation.required")),
        yup.object().shape({
            filters: yup.array().of(
                yup
                    .object()
                    .shape({
                        name: yup.string(),
                        value: yup.lazy((value) =>
                            Array.isArray(value)
                                ? yup
                                      .array()
                                      .of(
                                          yup.object().shape({
                                              id: yup.number(),
                                              name_en: yup.string(),
                                              name_ar: yup.string(),
                                          })
                                      )
                                      .min(1, t("validation.required"))
                                : yup
                                      .object()
                                      .shape({
                                          id: yup.number(),
                                          name_en: yup.string(),
                                          name_ar: yup.string(),
                                      })
                                      .required(t("validation.required"))
                        ),
                    })
                    .required(t("validation.required"))
            ),
            value: yup.number().min(15),
        }),
        yup.object(),
    ];
};

export const lengthOfDelta = (deltaObj) => {
    var length = 0;
    deltaObj?.forEach(function (item) {
        if (
            Object.prototype.hasOwnProperty.call(item, "insert") &&
            item["insert"] !== "\n"
        ) {
            length = length + item["insert"].length;
        }
    });

    return length;
};

export const setJobDetailsFromBackend = (setValue, jobInfo) => {
    const detailsToSave = [
        "title",
        "number_vacancies",
        "description",
        "type",
        "location",
        "workplace_preference",
        "salary_range",
        "open_for_discussion",
        "benefits",
        "filters",
        "created_source",
        "status",
        "commission_based",
    ];
    detailsToSave.map((job_detail) => {
        setValue(job_detail, jobInfo[job_detail]);
    });
};

export const getFilterContent = (arrFilterContent, locale) => {
    if (Array.isArray(arrFilterContent)) {
        let values = [];
        for (let i = 0; i < arrFilterContent.length; i++) {
            values.push(arrFilterContent[i][`name_${locale}`]);
        }
        return locale == "en" ? values.join(",  ") : values.join("،  ");
    } else {
        return arrFilterContent[`name_${locale}`];
    }
};

export const getFilterName = (name) => {
    switch (name) {
        case "citieslookup_id":
            return "city";
        case "months_of_experience":
            return "job_form.experience";
        case "english_proficiency_id":
            return "english_proficiency.title";
        case "major_id":
            return "major";
        case "salary_expectations_id":
            return "job_form.salary";
        case "university_id":
            return "university";
        case "jobtypeslookup_id":
            return "job_type";
    }
};

export const getFilterNameFromJob = (name) => {
    switch (name) {
        case "citieslookup_id":
            return "city";
        case "months_of_experience":
            return "months_of_experience";
        case "english_proficiency_id":
            return "english_proficiency";
        case "major_id":
            return "major";
        case "salary_expectations_id":
            return "salary_expectations";
        case "jobtypeslookup_id":
            return "job_type";
    }
};

export const getFilterNameForJobCreation = (name) => {
    switch (name) {
        case "residence_city":
            return "citieslookup_id";
        case "months_of_experience":
            return "months_of_experience";
        case "english_proficiency":
            return "english_proficiency_id";
        case "major":
            return "major_id";
        case "salary_expectations":
            return "salary_expectations_id";
        case "job_type":
            return "jobtypeslookup_id";
        default:
            return null;
    }
};

export const addCheckedFalse = (benefitsArr) => {
    let newBenefitsArr = [];
    for (let i = 0; i < benefitsArr?.length; i++) {
        newBenefitsArr.push({
            info: benefitsArr[i],
            checked: false,
        });
    }
    return newBenefitsArr;
};

export const onNextPage = async (
    formRef,
    trigger,
    setCurrentStep,
    currentStep
) => {
    formRef.current.scrollTo({ top: 0, behavior: "smooth" });
    const isStepValid = await trigger();
    if (isStepValid) setCurrentStep(currentStep + 1);
};

export const onPrevPage = (formRef, setCurrentStep, currentStep) => {
    formRef.current.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentStep(currentStep - 1);
};

import { MenuBookOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

export const guideButton = (t, goToGuide) => (
    <Button onClick={() => goToGuide()} startIcon={<MenuBookOutlined />}>
        {window.innerWidth > 550 ? t("job_form.guide.title") : ""}
    </Button>
);

export const goToGuide = (setCurrentStep, track) => {
    setCurrentStep(0);
    track.go_to_guide();
};

export const cancelForm = (getValues, navigate) => {
    if (getValues("title") !== "" && !getValues("title")) {
        navigate("/hp/jobs");
    } else {
        document.getElementById("cancel-form").style.display = "flex";
    }
};

export const JobSourceEnum = {
    DEFAULT: "Default",
    FILTERS: "Filters",
    INTERVIEW_SCHEDULING: "InterviewScheduling",
    CANDIDATE_LIST: "CandidateList",
    CANDIDATE_PROFILE: "CandidateProfile",
};

export const EditPageSourceEnum = {
    JOB_LIST: "job_list",
    JOB_DETAILS: "job_detail_page",
    FILTER_CREATE: "creating_job_from_filters",
    FILTER_EDIT: "editing_job_from_filters",
};

export const JobStatusEnum = {
    OPENED: 1,
    CLOSED: 2,
    PAUSED: 3,
    UNDER_REVIEW: 4,
};

export const JobStatusEnumsById = {
    1: "opened",
    2: "closed",
    3: "paused",
    4: "under-review",
};

export const JobICPQuestionEnum = {
    SINGLE_SELECT: "single_select",
    MULTI_SELECT: "multi_select",
    TEXT: "text",
    OPTIONAL_ANSWER: "optional_answer",
};
