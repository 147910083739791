import { Lookup } from "@/models/payload/PlacementFormPayload";
import React, {
    createContext,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
} from "react";

interface Lookups {
    cities: Array<Lookup>;
    job_benefits: Array<Lookup>;
    skills: Array<Lookup>;
}

const LookupsStateContext = createContext<Lookups | object>({});
const LookupsSetStateContext = createContext<Dispatch<SetStateAction<object>>>(
    () => {}
);

export const getLookups = () => {
    return useContext(LookupsStateContext) as Lookups;
};

export const getSetLookups = () => {
    return useContext(LookupsSetStateContext);
};

interface Props {
    children: React.ReactNode;
}

export const LookupsProvider = (props: Props) => {
    const [lookups, setLookups] = useState({});

    useEffect(() => {}, []);

    return (
        <LookupsStateContext.Provider value={lookups}>
            <LookupsSetStateContext.Provider value={setLookups}>
                {props.children}
            </LookupsSetStateContext.Provider>
        </LookupsStateContext.Provider>
    );
};
