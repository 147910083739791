import React, { FunctionComponent } from "react";
import { Button, ButtonProps, SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import clsx from "clsx";
import "./button.scss";
export interface ScalersButtonProps extends ButtonProps {
    text: string;
    color:
        | "primary"
        | "secondary"
        | "success"
        | "danger"
        | "info"
        | "warning"
        | "default";
    disabled?: boolean;
    variant?: "contained" | "outlined" | "text";
    prefix?: React.ReactElement;
    suffix?: React.ReactElement;
    type?: "button" | "submit" | "reset" | undefined;
    children?: React.ReactNode;
    size?: "small" | "medium" | "large";
    fullWidth?: boolean;
    onClick?: () => void;
    sx?: SxProps<Theme> | undefined;
}

type Props = ScalersButtonProps;

const ScalersButton: FunctionComponent<Props> = ({
    text,
    color = "primary",
    disabled = false,
    variant = "outlined",
    prefix,
    suffix,
    type,
    children,
    fullWidth,
    size,
    style,
    onClick,
    ...rest
}) => {
    return (
        <Button
            className={clsx("button", {
                "button--primary": color === "primary",
                "button--secondary": color === "secondary",
                "button--success": color === "success",
                "button--danger": color === "danger",
                "button--info": color === "info",
                "button--warning": color === "warning",
                "button--default": color === "default",
            })}
            classes={{
                disabled: "button--disabled",
            }}
            disabled={disabled}
            variant={
                variant === "contained"
                    ? "contained"
                    : variant === "outlined"
                      ? "outlined"
                      : "text"
            }
            type={type}
            size={size}
            fullWidth={fullWidth}
            onClick={onClick}
            startIcon={prefix}
            endIcon={suffix}
            style={{ ...style, boxShadow: "none" }}
            {...rest}
        >
            {text}
            {children && children}
        </Button>
    );
};

export default ScalersButton;
