import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import { CloseModalContext } from "./Context";

export default function ModalSingleMainAction(props) {
    const { closeModal } = useContext(CloseModalContext);

    return (
        <Button
            className="single-action"
            variant="contained"
            onClick={() => {
                closeModal();
                props.action();
            }}
            type={props.type}
        >
            {props.buttonContent}
        </Button>
    );
}
