export const CandidateProfileStatusEnum = {
    COMPLETED: 1,
    PENDING_VIDEO_SUBMISSION: 2,
    PENDING_EXP_AND_PREFERENCES: 3,
    GENERIC: 4
};


export const WorkplaceType = {
    OnSite : 1,
    Remote : 2,
    Hybrid : 3,
}