import React, { useContext } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { CloseModalContext } from "./Context";
import { IconButton } from "@mui/material";
import LocaleContext from "@/contexts/LocaleContext";

export default function ModalXButton() {
    const { closeModal } = useContext(CloseModalContext);
    const { locale } = useContext(LocaleContext);
    return (
        <IconButton onClick={closeModal} className={`modal-close ${locale}`}>
            <CloseOutlined />
        </IconButton>
    );
}
