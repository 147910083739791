import { getUser } from "@/contexts/UserContext";
import singletonLocalStorage from "../../utils/localStoring";
import { Mixpanel, captureEvent, trackTime } from "../Mixpanel";
import {
    prefix_to_object,
    free_trial_props,
    getSubscriptionDetails,
    getActiveVertical,
} from "../utils";
import store from "@/core/stores";
import { CandidateApplicationSourceByName } from "@/components/JobApplication/enums";

export class CandidateDetailsTrack {
    state = store.getState();
    constructor(props) {
        this.vertical = this.state.selectedVertical.vertical;
        this.currUser = getUser();
        (this.candidate = props.candidate),
            (this.location = props.location),
            (this.state = props.location?.state),
            (this.came_from = props.came_from),
            (this.page_enter = "Candidate Viewed"),
            (this.candidate_list =
                singletonLocalStorage.getItem("candidates_list")),
            (this.page_enter_exit = "Candidate Viewed - Page Exited"),
            (this.curr_index =
                this.candidate_list?.indexOf(this.candidate.current?.id) + 1),
            (this.out_of = this.candidate_list?.length);
    }

    page_view = (candidate_details) => {
        captureEvent(this.page_enter, {
            ...candidate_details,
            navigated_from: this.state?.source || "page reload",
            candidate_navigation_out_of: this.out_of,
            candidate_navigation_current_index:
                this.candidate_list?.indexOf(candidate_details?.id) + 1,
            initial_navigation_from: this.came_from,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
        trackTime(this.page_enter_exit);
        Mixpanel.people.increment("total_profiles_visited", 1); // increment total profiles visited
    };
    page_view_exit = (extraAttributes) => {
        captureEvent(this.page_enter_exit, {
            ...this.candidate.current,
            ...extraAttributes,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };

    all_actions = (actionName, extraAttributes) => {
        captureEvent(actionName, {
            from: "Candidate Page",
            ...this.candidate.current,
            candidate_navigation_out_of: this.out_of,
            candidate_navigation_current_index: this.curr_index,
            ...free_trial_props(this.currUser),
            ...extraAttributes,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
        if (actionName == "Candidate Shortlisted") {
            Mixpanel.people.increment("total_shortlist_count", 1);
        }
    };
    video_related_actions = (actionName, extraProps) => {
        captureEvent(actionName, {
            from: "Candidate Page",
            ...this.candidate.current,
            candidate_navigation_out_of: this.out_of,
            candidate_navigation_current_index: this.curr_index,
            ...free_trial_props(this.currUser),
            user_type: this.currUser.views,
            ...extraProps,
        });
    };
    comment_submitted = (comment, extraAttributes) => {
        captureEvent("Comment Submitted", {
            ...this.candidate,
            candidate_navigation_out_of: this.out_of,
            candidate_navigation_current_index: this.curr_index,
            ...free_trial_props(this.currUser),
            content: comment,
            ...extraAttributes,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    comment_deleted = (comment, extraAttributes) => {
        captureEvent("Comment Deleted", {
            ...this.candidate,
            candidate_navigation_out_of: this.out_of,
            candidate_navigation_current_index: this.curr_index,
            ...free_trial_props(this.currUser),
            content: comment.content,
            ...extraAttributes,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    pipeline_transition = (columns, pipeline, to, extraAttributes) => {
        captureEvent("Pipeline Transition", {
            action_from: "Pipeline Preview - Candidate Page",
            from: columns[pipeline.position],
            was_candidate_automatically_curated: [
                CandidateApplicationSourceByName.MATCHED_SOURCED,
                CandidateApplicationSourceByName.MATCHED_PLATFORM,
            ].includes(pipeline.source),
            to: to,
            ...this.candidate.current,
            candidate_navigation_out_of: this.out_of,
            candidate_navigation_current_index: this.curr_index,
            ...free_trial_props(this.currUser),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
            ...extraAttributes,
        });
    };
    delist_candidate = (action, reasons, extraAttributes) => {
        captureEvent(action, {
            no_hire_reasons: reasons,
            from: "Candidate Page",
            candidate_navigation_out_of: this.out_of,
            candidate_navigation_current_index: this.curr_index,
            ...this.candidate.current,
            ...free_trial_props(this.currUser),
            ...extraAttributes,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
        Mixpanel.people.increment("total_delist", 1);
    };
    resume_downloaded = () => {
        captureEvent("Resume Downloaded", {
            user_type: this.currUser.views,
            company: this.currUser.company,
            ...prefix_to_object(this.candidate.current, "candidate_"),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    information_viewed = (type) => {
        captureEvent(`${type} Information Viewed`, {
            user_type: this.currUser.views,
            company: this.currUser.company,
            ...prefix_to_object(this.candidate.current, "candidate_"),
            from: "Candidate Page",
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    tab_viewed = (type, from) => {
        captureEvent(`${type} Viewed`, {
            user_type: this.currUser.views,
            company: this.currUser.company,
            from,
            ...prefix_to_object(this.candidate.current, "candidate_"),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    tab_exited = (type, from, duration) => {
        const candidate = this.candidate?.current || this.candidate || {};
        captureEvent(`${type} Viewed - exited`, {
            user_type: this.currUser.views,
            company: this.currUser.company,
            duration,
            from,
            ...prefix_to_object(candidate, "candidate_"),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    show_more_clicked = (type) => {
        captureEvent(`${type} Show More Clicked`, {
            user_type: this.currUser.views,
            company: this.currUser.company,
            from: "Candidate Page",
            ...prefix_to_object(this.candidate.current, "candidate_"),
        });
    };
    interview_scheduling_clicked = () => {
        captureEvent("Interview Scheduling Button Clicked", {
            user_type: this.currUser.views,
            company: this.currUser.company,
            from: "Candidate Page",
            ...prefix_to_object(this.candidate.current, "candidate_"),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    offer_sent_clicked = () => {
        captureEvent("Offer Sent Button Clicked", {
            user_type: this.currUser.views,
            company: this.currUser.company,
            from: "Candidate Page",
            ...prefix_to_object(this.candidate.current, "candidate_"),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };

    candidate_shortlisted = "Candidate Shortlisted";
    create_job_post_button_click = "Create Job Post Button Clicked";
    shortlist_button_clicked = "Shortlist Button Clicked";

    candidateShortlisted(
        from,
        candidate_details,
        free_trial_data,
        verified,
        jobs
    ) {
        captureEvent(this.candidate_shortlisted, {
            from,
            ...candidate_details,
            ...free_trial_data,
            verified,
            ...jobs,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    }

    createJobPostButtonClicked(
        from,
        candidate_details,
        free_trial_data,
        verified
    ) {
        captureEvent(this.create_job_post_button_click, {
            from,
            verified,
            ...candidate_details,
            ...free_trial_data,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    }

    shortlistButtonClicked(from, candidate_details, company_details) {
        captureEvent(this.shortlist_button_clicked, {
            from,
            ...candidate_details,
            ...company_details,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    }
}
