import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";

const useMediaBreakpoint = () => {
    const theme = useTheme();
    const [breakpoint, setBreakpoint] = React.useState("lg");

    const xl = useMediaQuery(theme.breakpoints.up("xl"));
    const lg = useMediaQuery(theme.breakpoints.between("lg", "xl"));
    const md = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const sm = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const xs = useMediaQuery(theme.breakpoints.between("xs", "sm"));

    useEffect(() => {
        if (xl) {
            setBreakpoint("lg");
        } else if (lg) {
            setBreakpoint("lg");
        } else if (md) {
            setBreakpoint("md");
        } else if (sm) {
            setBreakpoint("sm");
        } else if (xs) {
            setBreakpoint("xs");
        }
    }, [breakpoint, xl, lg, md, sm, xs]);

    return { breakpoint };
};
export default useMediaBreakpoint;
