import React, { useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import axiosInstance from "../../../static/axios";
import { getUser } from "../../contexts/UserContext";
import close_img from "../../../static/images/close.png";
import "../../../static/css/modal.scss";
import { Factory, Pages } from "../../mixpanel-tracking/hp/factory";
import { CheckCircleOutline } from "@mui/icons-material";

function VerifyEmail(props) {
    const { t } = props;
    const [message, setMessage] = useState();
    const [notSent, setNotSent] = useState(true);

    const currUser = getUser();

    const factory = new Factory();
    let track = factory.get_tracker(Pages.EXTRA_TRACKER);

    const closeModal = () => {
        document.getElementById("complete-profile").style.display = "none";
    };
    const verify_email = () => {
        document.getElementById("verify_button").style.display = "none";
        axiosInstance
            .post("user/send_verify_email/", {
                email: currUser.email,
                id: currUser.id,
            })
            .then(() => {
                track.verify_request(currUser.email);
                setMessage(t("verify_modal.sent"));
                setNotSent(false);
                setTimeout(() => {
                    document.getElementById("complete-profile").style.display =
                        "none";
                }, 5000);
            });
    };

    return (
        <div
            id={"complete-profile"}
            className="modal"
            style={{ display: "none" }}
            onClick={closeModal}
        >
            <div
                id="modal-content"
                className="modal-content"
                onClick={(e) => {
                    e.stopPropagation();
                    return;
                }}
            >
                <div className="top-complete">
                    {notSent ? (
                        <FiAlertCircle size={46} />
                    ) : (
                        <CheckCircleOutline
                            sx={{
                                color: "green",
                                fontSize: "3em",
                            }}
                        />
                    )}
                </div>
                <p>
                    {" "}
                    {notSent
                        ? currUser.views == "hp"
                            ? t("verify_modal.message")
                            : t("verify_modal.candidate_message")
                        : message}
                </p>
                {notSent ? (
                    <button
                        id="verify_button"
                        className="filled-button"
                        onClick={verify_email}
                    >
                        {t("verify_modal.button")}
                    </button>
                ) : null}
                <img
                    src={close_img}
                    className="modal-close"
                    onClick={closeModal}
                />
            </div>
        </div>
    );
}

export default VerifyEmail;
