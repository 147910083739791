import React from "react";
import { Box, Button, Stack } from "@mui/material";
import ScalersTypography from "../atoms/typography/typography";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useSelector } from "react-redux";
import Modal from "./Modal";
import { setNavigateAwayLink } from "@/core/stores/general/general.store";
import { useDispatch } from "react-redux";

function ExitDisclaimer() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateAwayLink = useSelector(
        (state) => state.general.navigateAwayLink
    );
    const dispatch = useDispatch();

    const handleModalClose = () => {
        document.getElementById("exit-disclaimer-modal").style.display = "none";
    };

    const resetNavigateLink = () => {
        dispatch(setNavigateAwayLink(""));
    };

    const handleButtonClick = (state) => {
        handleModalClose();
        switch (state) {
            case "exit":
                resetNavigateLink();
                navigate(navigateAwayLink);
                break;
            case "back":
            default:
                break;
        }
    };

    return (
        <Modal
            type="exit-disclaimer-modal"
            closeModalActions={resetNavigateLink}
        >
            <Stack width="100%" gap="12px" alignItems="center">
                <WarningAmberIcon className="warning-icon" />
                <ScalersTypography
                    variant="subtitle"
                    fontSize="19px"
                    fontWeight={700}
                >
                    {t("exit-modal.heading")}
                </ScalersTypography>
                <ScalersTypography
                    variant="body2"
                    fontSize="13px"
                    fontWeight={400}
                    color="#55525A"
                >
                    {t("exit-modal.subheading")}
                </ScalersTypography>
                <Box className="button-group">
                    <Button
                        variant="contained"
                        className="exit-button"
                        onClick={() => handleButtonClick("exit")}
                        data-testid={"Button-exit-disclaimer-modal-exit"}
                    >
                        {t("exit")}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleButtonClick("back")}
                        data-testid={"Button-exit-disclaimer-modal-cancel"}
                    >
                        {t("cancel")}
                    </Button>
                </Box>
            </Stack>
        </Modal>
    );
}

export default ExitDisclaimer;
