import React, { useRef, useCallback } from "react";
import SuprSendInbox from "@suprsend/react-inbox";
import NotificationsNoneOutlined from "@mui/icons-material/NotificationsNoneOutlined";
import "./notification-menu.scss";
import "./menu-item.scss";
import emptyImage from "../../../../../static/images/empty-icon.svg";
import { useTranslation } from "react-i18next";
import { Factory, Pages } from "@/mixpanel-tracking/hp/factory";
import {
    NotificationBellTracking,
    NotificationChannel,
    NotificationItemTracking,
} from "@/mixpanel-tracking/hp/notification-tracking";
import { useNavigate } from "react-router-dom";
import {
    StageEnum,
    StatusEnum,
} from "@/pages/candidate/fast-track/fast-track-steps/enums";
import clsx from "clsx";

interface NotificationMenuProps {
    isSideBarOpen: boolean;
    user: {
        supersend: {
            subscriber_id: string;
            distinct_id: string;
        };
        stage: {
            id: string;
        };
        status: {
            id: string;
        };
    };
}

interface Notification {
    message: {
        extra_data?: string;
        url?: string;
    };
}

const NotificationMenu: React.FC<NotificationMenuProps> = ({
    isSideBarOpen,
    user,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const menuRef = useRef<HTMLDivElement>(null);
    const factory = new Factory(user);
    const track_bell = factory.get_tracker(
        Pages.NOTIFICATION_BELL
    ) as NotificationBellTracking;
    const track_notification_item = factory.get_tracker(
        Pages.NOTIFICATION_ITEM
    ) as NotificationItemTracking;

    const notificationBoxCloseHandler = useCallback(
        (track_bell: NotificationBellTracking, event: MouseEvent) => {
            if (
                track_bell.counter_started &&
                menuRef.current &&
                !menuRef.current.contains(event.target as Node)
            ) {
                document
                    .getElementById("root")
                    ?.removeEventListener(
                        "click",
                        notificationBoxCloseHandler.bind(null, track_bell)
                    );
                track_bell.closed();
            }
        },
        []
    );

    const notificationBoxOpenHandler = () => {
        if (track_bell && !track_bell.counter_started) {
            track_bell.opened();
            document
                .getElementById("root")
                ?.addEventListener(
                    "click",
                    notificationBoxCloseHandler.bind(null, track_bell)
                );
        }
    };

    const notificationItemClickHandler = (notification: Notification) => {
        let extra_data = {};
        if (notification.message.extra_data) {
            extra_data = JSON.parse(notification.message.extra_data);
        }
        track_notification_item.click(
            notification,
            NotificationChannel.InApp,
            extra_data
        );
        if (notification.message.url) {
            // IF fast tracker and journey is completed take him / her to ''
            if (
                user.stage &&
                user.stage.id === String(StageEnum.MATCH_WITH_COMPANIES) &&
                user.status.id === String(StatusEnum.COMPLETE)
            ) {
                navigate("/candidate/jobs");
            } else if (
                notification.message.url.includes("hired.withscalers.com")
            ) {
                const url = new URL(notification.message.url);
                const path = url.pathname;
                navigate(path);
            } else {
                window.open(notification.message.url);
            }
        }
    };

    const openNotifications = () => {
        (menuRef.current?.firstChild?.firstChild as HTMLElement)?.click();
    };

    return (
        <div
            className={clsx("notificationWrapper", {
                "notificationWrapper--sidebar": isSideBarOpen,
            })}
            ref={menuRef}
            onClick={notificationBoxOpenHandler}
        >
            <SuprSendInbox
                notificationClickHandler={notificationItemClickHandler}
                workspaceKey={import.meta.env.VITE_SUPR_SEND_WORKSPACE_KEY}
                subscriberId={user.supersend?.subscriber_id}
                distinctId={user.supersend?.distinct_id}
                bellComponent={() => (
                    <div className="notificationWrapper__bell">
                        <NotificationsNoneOutlined />
                    </div>
                )}
                toastProps={{
                    position: "center-center",
                }}
                noNotificationsComponent={() => (
                    <div className="emptyWrapper">
                        <img
                            className="emptyWrapper__img"
                            src={emptyImage}
                            alt="emptyImg"
                        />
                        <div className="emptyWrapperContent">
                            <h6 className="emptyWrapper__heading">
                                {t("notifications.empty-state-heading")}
                            </h6>
                            <p className="emptyWrapper__desc">
                                {t("notifications.empty-state-message")}
                            </p>
                        </div>
                    </div>
                )}
                theme={{
                    notification: {
                        unseenDot: { backgroundColor: "#C96657" },
                    },

                    badge: {
                        backgroundColor: "#C96657",
                        color: "#fff",
                        margin: "0px",
                        borderRadius: "100px",
                        width: "16px",
                        height: "16px",
                        fontSize: "11px",
                    },

                    header: {
                        container: {
                            backgroundColor: "#fafafd",
                            boxShadow: "none",
                            borderBottom: "1px solid #EAE9EA",
                            padding: "17px 24px",
                        },
                        markAllReadText: { color: "#6C2F83" },
                    },
                }}
            />
            {isSideBarOpen && (
                <div onClick={openNotifications} className="menu-item-bottom">
                    {t("notifications.title")}
                </div>
            )}
        </div>
    );
};

export default NotificationMenu;
