import React, { FC } from "react";

interface Props {
    image:any;
}
const ModalIllustration: FC<Props> = ({ image }: Props) => {
    return (
        <img src={image} className="modal-illustration"/>
    )
}

export default ModalIllustration