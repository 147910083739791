import { RouteInterface } from "@/core/interfaces/route.interface";
import { lazy } from "react";

const ChangePhone = lazy(() => import("@/components/ChangePhone/ChangePhone"));
const SignupWizard = lazy(() => import("@/pages/hp/SignupWizard"));

const routes: Array<RouteInterface> = [
    {
        path: "hp/signup-wizard",
        element: SignupWizard,
        name: "Signup Wizard",
    },
    {
        path: "change-phone",
        element: ChangePhone,
        name: "Change Phone",
    },
];

const GlobalRoutes = routes;
export default GlobalRoutes;
