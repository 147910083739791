import React from "react";
import Lottie from "lottie-react";
import animationPrimary from "@/../static/animations/OTTeAB2K7N.json";

const ScalersLoader: React.FC = () => {
    return (
        <div className="loader-container">
            <Lottie
                animationData={animationPrimary}
                style={{
                    width: "180px",
                    height: "180px",
                }}
            />
        </div>
    );
};

export default ScalersLoader;
