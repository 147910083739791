import React from "react";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import i18n from "./i18n";
import { ThemeProvider, createTheme } from "@mui/material";

function RTL(Component) {
    function Rtl(props) {
        const cacheLtr = createCache({
            key: "muiltr"
        }); 
    
        const cacheRtl = createCache({
            key: "muirtl",
            stylisPlugins: [prefixer, rtlPlugin]
        });
        return (
            <CacheProvider value={i18n.dir() == "rtl" ? cacheRtl : cacheLtr}>
                <ThemeProvider theme={createTheme({
                    direction : i18n.dir()
                })}>
                    <Component {...props}/>
                </ThemeProvider> 
            </CacheProvider>
        )
    }
    return Rtl
}

export default RTL