import { factory, primaryKey } from '@mswjs/data';
import {uid} from "../../utils/uid";


const models = {
    job: {
        id: primaryKey(uid),
        title: String,
        icon: String,
        company: String,
        opportunity: {
            location: String,
            type: String,
            expected_salary: String,
            work_time: String
        },
        benefits:[
            {
                checked:Boolean,
                info:{
                    id:String,
                    desc_ar:String,
                    desc_en:String,
                    name_ar:String,
                    name_en:String,
                    icon:String,
                }
            }
        ]
    },
    jobDetails: {
        id:primaryKey(String),
        icon:String,
        company:{
            name:String,
            field:String,
            description:String,
            social:[{
                id:String,
                link:String,
                type:String
            }]


        },
        opportunity: {

        }
    }
};

export const db = factory(models);