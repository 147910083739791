// @flow
import React from "react";
import ScalersLayout from "@/components/molecules/layout/layout";
import { Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
const ContentLoader = () => {
    return (
        <ScalersLayout>
            <Stack
                direction="column"
                style={{
                    width: "100%",
                }}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Skeleton variant="text" width={140} height={30} />
                    <Skeleton variant="text" width={100} height={20} />
                </Stack>
                <Skeleton variant="text" width={110} height={20} />
            </Stack>
        </ScalersLayout>
    );
};

export default ContentLoader;
