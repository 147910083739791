import React, { useContext } from 'react';
import "../../static/css/banner.css"
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { LocalFireDepartment } from '@mui/icons-material';
import { getJobApplicants, getSetJobApplicants } from '../contexts/JobsNotifyContext';
import { useNavigate } from 'react-router-dom';
import singletonLocalStorage from '../utils/localStoring';

export default function JobApplicantsBanner() {
    const { t } = useTranslation()
    const jobApplicants = getJobApplicants()
    const setJobApplicants = getSetJobApplicants()
    const navigate = useNavigate()

    return (jobApplicants.show_banner && !window.location.pathname.includes('hp/jobs')) && <div className="job-applicants-banner">
        <p><LocalFireDepartment />{jobApplicants.total_number} {t('jobs_applicants_notice.banner_message')}</p>
        <Button variant='text' onClick={() => navigate(jobApplicants.navigate_to)}>{t('jobs_applicants_notice.button')}</Button>
        <CloseOutlined className='x-button' onClick={() => {
            singletonLocalStorage.setItem("show_banner", "false")
            setJobApplicants({...jobApplicants, show_banner:false})
        }} />
    </div>
}