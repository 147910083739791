import { CurrUserDetails } from "@/utils/currUserType";
import React, {
    createContext,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
} from "react";
import { useGrowthBook } from "@growthbook/growthbook-react";

const UserContext = createContext<CurrUserDetails | {}>({});
const UserSetContext = createContext<
    Dispatch<SetStateAction<CurrUserDetails | {}>>
>(() => {});

export const getUser = () => {
    return useContext(UserContext) as CurrUserDetails;
};

export const getSetUser = () => {
    return useContext(UserSetContext);
};

interface Props {
    children: React.ReactNode;
}

export const UserProvider = (props: Props) => {
    const [user, setUser] = useState<CurrUserDetails | {}>({});
    const growthbook = useGrowthBook();

    // Function to update user details and GrowthBook attributes
    const updateUserDetails = (newUserDetails: CurrUserDetails) => {
        setUser(newUserDetails);

        // Update user attributes in GrowthBook
        growthbook?.setAttributes({
            id: newUserDetails.id,
            company_id: newUserDetails?.company?.id,
            // Add other user attributes as needed
        });
    };

    return (
        <UserContext.Provider value={user}>
            <UserSetContext.Provider value={updateUserDetails}>
                {props.children}
            </UserSetContext.Provider>
        </UserContext.Provider>
    );
};
