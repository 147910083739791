import ImgTitleSubtitle from "@/components/ImgTitleSubtitle"
import { Button } from "@mui/material"
import React from "react"
import image from "@/../static/images/error-candidates.svg";
import { useTranslation } from "react-i18next";

export default function DefaultError() {
    const { t } = useTranslation()

    return (
        <div className="error-page">
            <div className="error-content">
                <ImgTitleSubtitle title={t('error_page.title')} img={image} subtitle={t('error_page.default_subtitle')} />
                <Button variant="contained" onClick={() => window.location.replace(`${window.location.origin}/`)}>{t('error_page.button')}</Button>
            </div>
        </div>
   )
}