import { rest } from "msw";
import { uid } from "@/utils/uid";

const mockData = {
  sales: {
    application_id: "a32aa6bf-9079-4fb6-bf1b-1fdb3b04b895",
    id: "38b10771-74b7-40d8-bd6c-60a3ef61f195",
    email: "hhabtur5t@withscalers.com",
    name: "هاشم تست",
    gender: null,
    name_eng: "Hashim Habtur Test",
    phone: "509405710",
    email_verified: false,
    email_verification_date: null,
    nationality: null,
    age: 22,
    has_bachelors: false,
    other_university: null,
    months_of_experience: 24,
    date_applied: "2023-10-11T08:39:46.024795Z",
    city: 11,
    english_proficiency: 1,
    university: 6,
    applicant_ptr: "38b10771-74b7-40d8-bd6c-60a3ef61f195",
    type: 1,
    views: "fast-tracker",
    status: {
      id: 1,
      name_ar: "في انتظار المتقدم",
      name_en: "Awaiting Submission",
    },
    stage: {
      id: 5,
      name_ar: "اختبار المبيعات النظري",
      name_en: "Sales Assessment",
    },
    assessment_score: 40,
    active_application: 2,
    assessment_url: "https://www.google.com",
  },
  personalProfile: {
    application_id: "a32aa6bf-9079-4fb6-bf1b-1fdb3b04b895",
    id: "38b10771-74b7-40d8-bd6c-60a3ef61f195",
    email: "hhabtur5t@withscalers.com",
    name: "هاشم تست",
    gender: null,
    name_eng: "Hashim Habtur Trst",
    phone: "509405710",
    email_verified: false,
    email_verification_date: null,
    nationality: null,
    age: 22,
    has_bachelors: false,
    other_university: null,
    months_of_experience: 24,
    date_applied: "2023-10-11T08:39:46.024795Z",
    city: 11,
    english_proficiency: 1,
    university: 6,
    applicant_ptr: "38b10771-74b7-40d8-bd6c-60a3ef61f195",
    type: 1,
    views: "fast-tracker",
    status: {
      id: 7,
      name_ar: "مجتازة",
      name_en: "Complete",
    },
    stage: {
      id: 4,
      name_ar: "الملف الشخصي",
      name_en: "Candidate Profile",
    },
    assessment_score: 95,
    assessment_url: "https://www.flexiquiz.com/SC/S?p=None&rg=t",
    refresh:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY5NzU5ODUyNiwiaWF0IjoxNjk3NTU1MzI2LCJqdGkiOiI2MDljMGU4OTQ1YTg0NWE2YWYxMDAwODkzOTU3Njc1MyIsInVzZXJfaWQiOiIzOGIxMDc3MS03NGI3LTQwZDgtYmQ2Yy02MGEzZWY2MWYxOTUifQ.9ofxuSLhb8U1_o6nFALC9a73vd5V_zaZmh25j0zOYtY",
    access:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk3NjI3MzI2LCJpYXQiOjE2OTc1NTUzMjYsImp0aSI6Ijg0NTA2NWZhYzdjNDQ1Njc5YTBjZjFjZGU2OGM5ZGQzIiwidXNlcl9pZCI6IjM4YjEwNzcxLTc0YjctNDBkOC1iZDZjLTYwYTNlZjYxZjE5NSJ9.EeR1E3BkUyBLxSUcQPc_zmCIcnzgDLtOQLfxlsm-flo",
  },
  videoInterview: {
    application_id: "a32aa6bf-9079-4fb6-bf1b-1fdb3b04b895",
    id: "38b10771-74b7-40d8-bd6c-60a3ef61f195",
    email: "hhabtur5t@withscalers.com",
    name: "هاشم تست",
    gender: null,
    name_eng: "Hashim Habtur Trst",
    phone: "509405710",
    email_verified: false,
    email_verification_date: null,
    nationality: null,
    age: 22,
    has_bachelors: false,
    other_university: null,
    months_of_experience: 24,
    date_applied: "2023-10-11T08:39:46.024795Z",
    city: 11,
    english_proficiency: 1,
    university: 6,
    applicant_ptr: "38b10771-74b7-40d8-bd6c-60a3ef61f195",
    type: 1,
    views: "fast-tracker",
    status: {
      id: 1,
      name_ar: "مجتازة",
      name_en: "Complete",
    },
    stage: {
      id: 3,
      name_ar: "الملف الشخصي",
      name_en: "Candidate Profile",
    },
    assessment_score: 60,
    assessment_url: "https://www.flexiquiz.com/SC/S?p=None&rg=t",
    active_jobs: 2,
    refresh:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY5NzU5ODUyNiwiaWF0IjoxNjk3NTU1MzI2LCJqdGkiOiI2MDljMGU4OTQ1YTg0NWE2YWYxMDAwODkzOTU3Njc1MyIsInVzZXJfaWQiOiIzOGIxMDc3MS03NGI3LTQwZDgtYmQ2Yy02MGEzZWY2MWYxOTUifQ.9ofxuSLhb8U1_o6nFALC9a73vd5V_zaZmh25j0zOYtY",
    access:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk3NjI3MzI2LCJpYXQiOjE2OTc1NTUzMjYsImp0aSI6Ijg0NTA2NWZhYzdjNDQ1Njc5YTBjZjFjZGU2OGM5ZGQzIiwidXNlcl9pZCI6IjM4YjEwNzcxLTc0YjctNDBkOC1iZDZjLTYwYTNlZjYxZjE5NSJ9.EeR1E3BkUyBLxSUcQPc_zmCIcnzgDLtOQLfxlsm-flo",
  },
};

export const handlers = [
  rest.get(`${import.meta.env.VITE_BASE_URL}/healthcheck`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ healthy: true }));
  }),
  rest.get(`${import.meta.env.VITE_BASE_URL}/jobs`, (req, res, context) => {
    return res(
      context.status(200),
      context.json({
        count: 10,
        next: "",
        previous: null,
        data: [
          {
            id: 1,
            created_date: "2023-07-23",
            salary_range: [5500, 6000],
            location: {
              id: 3,
              name_ar: "الباحة",
              name_en: "Al Bahah",
            },
            type: {
              id: 1,
              name_ar: "دوام جزئي",
              name_en: "Part-time",
            },
            status: {
              id: 1,
              name_ar: "متاح",
              name_en: "avilaiable",
            },
            workplace_preference: {
              id: 1,
              name_ar: "دوام كامل",
              name_en: "Full time",
            },
            company: {
              name: "Scalers",
              logo: "https://s3.me-south-1.amazonaws.com/testing-media-local/https%3A/media.licdn.com/dms/image/C4D0BAQHksGjCTvmK5Q/company-logo_200_200/0/1652981799938%3Fe%3D2147483647%26v%3Dbeta%26t%3DENLMc9_T7QObjG0M7ZyrVQ_0A_ygwWkJCKko9H2KAas?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T124152Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=6cec432eebcfa464fc035ba39fecba1b37b028ca78e0064d88edebffe4c0c8e2",
            },
            benefits: [],
            title: "اخصائي مبيعات",
          },
          {
            id: 2,
            created_date: "2023-07-23",
            salary_range: [5500, 6000],
            location: {
              id: 1,
              name_ar: "ابها",
              name_en: "Abha",
            },
            type: {
              id: 2,
              name_ar: "دوام كامل",
              name_en: "Full-time",
            },
            status: {
              id: 1,
              name_ar: "متاح",
              name_en: "avilaiable",
            },
            workplace_preference: {
              id: 3,
              name_ar: "هجين (حضور و عن بعد)",
              name_en: "Hybrid",
            },
            company: {
              name: "Scalers",
              logo: "https://s3.me-south-1.amazonaws.com/testing-media-local/https%3A/media.licdn.com/dms/image/C4D0BAQHksGjCTvmK5Q/company-logo_200_200/0/1652981799938%3Fe%3D2147483647%26v%3Dbeta%26t%3DENLMc9_T7QObjG0M7ZyrVQ_0A_ygwWkJCKko9H2KAas?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T124152Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=6cec432eebcfa464fc035ba39fecba1b37b028ca78e0064d88edebffe4c0c8e2",
            },
            benefits: [],
            title: "saddasasddassad",
          },
          {
            id: 3,
            created_date: "2023-07-23",
            salary_range: [5500, 6000],
            location: {
              id: 1,
              name_ar: "ابها",
              name_en: "Abha",
            },
            type: {
              id: 2,
              name_ar: "دوام كامل",
              name_en: "Full-time",
            },
            status: {
              id: 1,
              name_ar: "متاح",
              name_en: "avilaiable",
            },
            workplace_preference: {
              id: 3,
              name_ar: "هجين (حضور و عن بعد)",
              name_en: "Hybrid",
            },
            company: {
              name: "Scalers",
              logo: "https://s3.me-south-1.amazonaws.com/testing-media-local/https%3A/media.licdn.com/dms/image/C4D0BAQHksGjCTvmK5Q/company-logo_200_200/0/1652981799938%3Fe%3D2147483647%26v%3Dbeta%26t%3DENLMc9_T7QObjG0M7ZyrVQ_0A_ygwWkJCKko9H2KAas?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T124152Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=6cec432eebcfa464fc035ba39fecba1b37b028ca78e0064d88edebffe4c0c8e2",
            },
            benefits: [
              {
                checked: true,
                info: {
                  id: 1,
                  name_ar: "تأمين صحي",
                  name_en: "Health insurance",
                  desc_en: null,
                  desc_ar: null,
                  icon: "https://s3.me-south-1.amazonaws.com/testing-media-local/static/images/Health%20Insurance.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T124152Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=d33beb9b1597979a83ba982f96f0bcd42bce7fe1eb9c45da77c59e27a1c575c0",
                },
              },
              {
                checked: true,
                info: {
                  id: 3,
                  name_ar: "أسهم",
                  name_en: "Stocks",
                  desc_en: null,
                  desc_ar: null,
                  icon: "https://s3.me-south-1.amazonaws.com/testing-media-local/static/images/Stocks.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T124152Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=f4f75ee2f236616ed6254e2c60c046f8fcac0fac8ca6312adb6bbb9d4ad888f8",
                },
              },
              {
                checked: true,
                info: {
                  id: 4,
                  name_ar: "بدل تغيير موقع",
                  name_en: "Relocation Allowance",
                  desc_en: null,
                  desc_ar: null,
                  icon: "https://s3.me-south-1.amazonaws.com/testing-media-local/static/images/Relocation%20Allowance.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T124152Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=59dca9e61a5ad7142bfc946bf281a111792d77cd4d91d9d2a14f30fcd3c34b4b",
                },
              },
              {
                checked: true,
                info: {
                  id: 2,
                  name_ar: "عمولة",
                  name_en: "Commission",
                  desc_en: null,
                  desc_ar: null,
                  icon: "https://s3.me-south-1.amazonaws.com/testing-media-local/static/images/Commissions.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T124152Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=0d3f7fb50893d58c4f4ba9735b552f5d2ff2610d300c318c1d970fd07eb1cb3b",
                },
              },
            ],
            title: "saddasasddassad",
          },
          {
            id: 4,
            created_date: "2023-07-24",
            salary_range: [5500, 6000],
            location: {
              id: 2,
              name_ar: "املج",
              name_en: "Umluj",
            },
            type: {
              id: 2,
              name_ar: "دوام كامل",
              name_en: "Full-time",
            },
            status: {
              id: 1,
              name_ar: "متاح",
              name_en: "avilaiable",
            },
            workplace_preference: {
              id: 1,
              name_ar: "دوام كامل",
              name_en: "Full time",
            },
            company: {
              name: "Scalers",
              logo: "https://s3.me-south-1.amazonaws.com/testing-media-local/https%3A/media.licdn.com/dms/image/C4D0BAQHksGjCTvmK5Q/company-logo_200_200/0/1652981799938%3Fe%3D2147483647%26v%3Dbeta%26t%3DENLMc9_T7QObjG0M7ZyrVQ_0A_ygwWkJCKko9H2KAas?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T124152Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=6cec432eebcfa464fc035ba39fecba1b37b028ca78e0064d88edebffe4c0c8e2",
            },
            benefits: [],
            title: "Software ِEngineer",
          },
          {
            id: 5,
            created_date: "2023-07-24",
            salary_range: [5500, 6000],
            location: {
              id: 11,
              name_ar: "الرياض",
              name_en: "Riyadh",
            },
            type: {
              id: 2,
              name_ar: "دوام كامل",
              name_en: "Full-time",
            },
            status: {
              id: 1,
              name_ar: "متاح",
              name_en: "avilaiable",
            },
            workplace_preference: {
              id: 1,
              name_ar: "دوام كامل",
              name_en: "Full time",
            },
            company: {
              name: "Scalers",
              logo: "https://s3.me-south-1.amazonaws.com/testing-media-local/https%3A/media.licdn.com/dms/image/C4D0BAQHksGjCTvmK5Q/company-logo_200_200/0/1652981799938%3Fe%3D2147483647%26v%3Dbeta%26t%3DENLMc9_T7QObjG0M7ZyrVQ_0A_ygwWkJCKko9H2KAas?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T124152Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=6cec432eebcfa464fc035ba39fecba1b37b028ca78e0064d88edebffe4c0c8e2",
            },
            benefits: [
              {
                checked: true,
                info: {
                  id: 1,
                  name_ar: "تأمين صحي",
                  name_en: "Health insurance",
                  desc_en: null,
                  desc_ar: null,
                  icon: "https://s3.me-south-1.amazonaws.com/testing-media-local/static/images/Health%20Insurance.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T124152Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=d33beb9b1597979a83ba982f96f0bcd42bce7fe1eb9c45da77c59e27a1c575c0",
                },
              },
              {
                checked: true,
                info: {
                  id: 2,
                  name_ar: "عمولة",
                  name_en: "Commission",
                  desc_en: null,
                  desc_ar: null,
                  icon: "https://s3.me-south-1.amazonaws.com/testing-media-local/static/images/Commissions.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T124152Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=0d3f7fb50893d58c4f4ba9735b552f5d2ff2610d300c318c1d970fd07eb1cb3b",
                },
              },
              {
                checked: true,
                info: {
                  id: 3,
                  name_ar: "أسهم",
                  name_en: "Stocks",
                  desc_en: null,
                  desc_ar: null,
                  icon: "https://s3.me-south-1.amazonaws.com/testing-media-local/static/images/Stocks.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T124152Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=f4f75ee2f236616ed6254e2c60c046f8fcac0fac8ca6312adb6bbb9d4ad888f8",
                },
              },
              {
                checked: true,
                info: {
                  id: 4,
                  name_ar: "بدل تغيير موقع",
                  name_en: "Relocation Allowance",
                  desc_en: null,
                  desc_ar: null,
                  icon: "https://s3.me-south-1.amazonaws.com/testing-media-local/static/images/Relocation%20Allowance.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T124152Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=59dca9e61a5ad7142bfc946bf281a111792d77cd4d91d9d2a14f30fcd3c34b4b",
                },
              },
            ],
            title: "Software ِEngineer",
          },
        ],
      })
    );
  }),
  rest.get(`${import.meta.env.VITE_BASE_URL}/jobs/applied`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        count: 10,
        next: "",
        previous: null,
        data: [
          {
            job_id: "6fbb2d24-1274-419e-801d-ed7e74e03dd1",
            created_at: "2023-07-23T16:16:52.031356Z",
            job: {
              id: 1,
              created_date: "2023-07-23",
              salary_range: [5500, 6000],
              location: {
                id: 3,
                name_ar: "الباحة",
                name_en: "Al Bahah",
              },
              type: {
                id: 1,
                name_ar: "دوام جزئي",
                name_en: "Part-time",
              },
              status: {
                id: 1,
                name_ar: "متاح",
                name_en: "avilaiable",
              },
              workplace_preference: {
                id: 1,
                name_ar: "دوام كامل",
                name_en: "Full time",
              },
              company: {
                name: "Scalers",
                logo: "https://s3.me-south-1.amazonaws.com/testing-media-local/https%3A/media.licdn.com/dms/image/C4D0BAQHksGjCTvmK5Q/company-logo_200_200/0/1652981799938%3Fe%3D2147483647%26v%3Dbeta%26t%3DENLMc9_T7QObjG0M7ZyrVQ_0A_ygwWkJCKko9H2KAas?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATSIUCQNSQVHUB4NM%2F20230724%2Fme-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230724T142058Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=324f1dc97ceb2abcf741a1ab88f46428e7b48301d5b7e12e7b6be5ba95283c24",
              },
              benefits: [],
              title: "اخصائي مبيعات",
            },
          },
        ],
      })
    );
  }),

  rest.get(
    `${import.meta.env.VITE_BASE_URL}/jobs/1234`,
    (req, res, context) => {
      return res(
        context.status(200),
        context.json({
          id: uid(),
          icon: "https://assets.asana.biz/m/4aecf2c7c66aa51b/original/asana-meta-coral-1x.png",
          company: {
            name: "Asana",
            field: "قطاع السياحة والضيافة",
            description:
              "أول منصة مرخصة من وزارة السياحة للسكن التشاركي (Peer to peer renting )تتيح  للمستخدمين استئجار فيلا أو شقة أو مزرعة أو بيت متنقل أو غرفة أو شاليه أو مخيم أو يخت ، من بين خيارات أخرى، مباشرة من المالك  بينما يكون للمالكين القدرة على طرح عقاراتهم للإيجار من خلال الموقع الإلكتروني والتطبيق دون الحاجة إلى الحصول على رخصة إيجار فردية.",
            twitter: "https://withscalers.com",
            website: "https://withscalers.com",
            linkedin: "https://withscalers.com",
          },
          opportunity: {
            title: "اخصائي مبيعات",
            location: "المدينة المنورة",
            type: "عن بعد",
            expected_salary: "9.5  -8.5 آلاف",
            work_time: "دوام جزئي",
            health_insurance: false,
            relocation_allowance: false,
            stocks: false,
            commission_field: false,
            description: {
              ops: [
                {
                  insert: "هذا تست للجوب بوستينج",
                },
                {
                  insert: "\n",
                  attributes: {
                    align: "right",
                    direction: "rtl",
                  },
                },
                {
                  insert: "هذا تست للجوب بوستينج",
                },
                {
                  insert: "\n",
                  attributes: {
                    list: "bullet",
                    align: "right",
                    direction: "rtl",
                  },
                },
                {
                  insert: "هذا تست للجوب بوستينج",
                },
                {
                  insert: "\n",
                  attributes: {
                    list: "bullet",
                    align: "right",
                    direction: "rtl",
                  },
                },
                {
                  insert: "هذا تست للجوب بوستينج",
                },
                {
                  insert: "\n",
                  attributes: {
                    list: "bullet",
                    align: "right",
                    indent: 1,
                    direction: "rtl",
                  },
                },
                {
                  insert:
                    "هذا تست للجوب بوستينج,هذا تست للجوب بوستينج,هذا تست للجوب بوستينج,هذا تست للجوب بوستينج,هذا تست للجوب بوستينج,هذا تست للجوب بوستينج,هذا تست للجوب بوستينج,هذا تست للجوب بوستينج,هذا تست للجوب بوستينج,هذا تست للجوب بوستينج,هذا تست للجوب بوستينج",
                  attributes: {
                    bold: true,
                  },
                },
                {
                  insert: "\n",
                  attributes: {
                    align: "right",
                    direction: "rtl",
                  },
                },
              ],
            },
          },
        })
      );
    }
  ),
  rest.get(
    `${import.meta.env.VITE_BASE_URL}/user/currUser/`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockData.videoInterview));
    }
  ),
];
