import { createSlice } from "@reduxjs/toolkit";

const notificationSnackbarInitialState = {
    showing: false,
    type: "success",
    variant: "outlined",
    hideDuration: 3000,
    relativeHorizontal: "start",
    vertical: "top",
    message: "",
    showIcon: false,
};

const generalSlice = createSlice({
    name: "general",
    initialState: {
        navigateAwayLink: "",
        notificationSnackbar: notificationSnackbarInitialState,
    },
    reducers: {
        setNavigateAwayLink: (state, action) => {
            state.navigateAwayLink = action.payload;
        },
        setGeneralSnackbar: (state, action) => {
            state.notificationSnackbar = action.payload;
        },
        setGeneralSnackbarInitial: (state) => {
            state.notificationSnackbar = {
                ...state.notificationSnackbar,
                showing: false,
            };
        },
    },
});

export const {
    setNavigateAwayLink,
    setGeneralSnackbar,
    setGeneralSnackbarInitial,
} = generalSlice.actions;

export default generalSlice.reducer;
