import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./user-profile-card.css";
import {
    ArrowDropDown,
    ArrowDropUp,
    SettingsOutlined,
    ContactSupportOutlined,
    ExitToApp,
} from "@material-ui/icons";
import MenuLink from "../../components/menu-link/menu-link";
import i18n from "@/i18n";
import { isOnboarding } from "@/utils/utilFunctions";
import { Button } from "@mui/material";

const UserProfileCard = ({
    locale,
    hideProfile = false,
    user,
    altColor,
    logout,
    contact,
}) => {
    const ref = useRef(null);
    const [arrowDown, setArrowDown] = React.useState(true);
    const { t } = useTranslation();
    const dir = i18n.dir();

    const getFirstTwoCharFromEachWord = (str) => {
        const words = str.split(" ");
        const firstChars = words.map((word) => word.charAt(0));
        return firstChars.join("").substring(0, 2);
    };
    const dropMenu = () => {
        setArrowDown(false);
    };

    useEffect(() => {
        if (!hideProfile && user !== undefined) {
            if (arrowDown) {
                document.querySelector(".profile-anchor").style.display =
                    "none";
            } else {
                document.querySelector(".profile-anchor").style.display =
                    "grid";
            }
        }
    }, [arrowDown]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setArrowDown(true);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    return (
        <div className="user-profile-card" onClick={dropMenu} ref={ref}>
            {user !== undefined ? (
                <>
                    {user.picture ? (
                        <img
                            className="image"
                            src={user.picture}
                            alt={
                                user.name &&
                                getFirstTwoCharFromEachWord(
                                    dir == "ltr" && user.name_eng
                                        ? user.name_eng
                                        : user.name
                                )
                            }
                            style={{ backgroundColor: altColor }}
                        />
                    ) : (
                        <div
                            className="image"
                            style={{ backgroundColor: altColor }}
                        >
                            {user.name &&
                                getFirstTwoCharFromEachWord(
                                    dir == "ltr" && user.name_eng
                                        ? user.name_eng
                                        : user.name
                                )}
                        </div>
                    )}
                    <div className="info">
                        {t("Hello")}
                        <br />
                        <span className="name">
                            {dir == "ltr" && user.name_eng
                                ? user.name_eng
                                : user.name}
                        </span>
                    </div>
                    {!hideProfile && (
                        <>{arrowDown ? <ArrowDropDown /> : <ArrowDropUp />}</>
                    )}

                    {!hideProfile && (
                        <div
                            className={`profile-anchor ${locale === "en" ? "ltr-profile-anchor" : ""}`}
                        >
                            {user.views === "hp" && (
                                <MenuLink
                                    text={t("settings")}
                                    to="hp/settings"
                                    isDisabled={isOnboarding(user)}
                                >
                                    <SettingsOutlined />
                                </MenuLink>
                            )}
                            <Button
                                className="menu-link"
                                onClick={contact}
                                startIcon={<ContactSupportOutlined />}
                            >
                                {t("contact_us.title")}
                            </Button>
                            <Button
                                className="menu-link text-button-danger"
                                onClick={logout}
                                startIcon={<ExitToApp />}
                            >
                                {t("logout.title")}
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <button className="filled-button">{t("login.title")}</button>
            )}
        </div>
    );
};
export default UserProfileCard;
