import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import LocaleProvider from "./local.provider";
import { UserProvider } from "@/contexts/UserContext";
import { LookupsProvider } from "@/contexts/LookupsStateContext";
import store from "@/core/stores";
import { BackgroundProvider } from "@/contexts/BackgroundContext";

interface AppProvidersProps {
    children: React.ReactNode;
}

const AppProviders: React.FC<AppProvidersProps> = ({ children }) => (
    <ReduxProvider store={store}>
        <LocaleProvider>
            <BackgroundProvider>
                <UserProvider>
                    <LookupsProvider>{children}</LookupsProvider>
                </UserProvider>
            </BackgroundProvider>
        </LocaleProvider>
    </ReduxProvider>
);

export default AppProviders;
