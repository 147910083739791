import { useState, useEffect } from "react";
import { JobApplicants } from "@/models/shared/applicant";
import axiosInstance from "../../static/axios";
import { useSelector } from "react-redux";
import { RootStateOrAny } from "react-redux";
import Verticals from "@/core/enums/verticals.enum";

interface Notifications {
    messages: boolean;
    curated_list: number;
    job_applicants: JobApplicants;
}

export const useNotifications = (
    jobApplicants: JobApplicants,
    setJobApplicants: (applicants: JobApplicants) => void
) => {
    const [messagesNotify, setMessagesNotify] = useState(false);
    const [curatedListCount, setCuratedListCount] = useState(0);
    const vertical = useSelector(
        (state: RootStateOrAny) => state.selectedVertical.vertical
    );
    const handleNotifications = async () => {
        if (vertical === Verticals.Accounting) {
            return;
        }
        try {
            const res = await axiosInstance.get(
                `/company/notifications?vertical_id=${vertical}`
            );
            const data: Notifications = res.data;
            setMessagesNotify(data.messages);
            setCuratedListCount(data.curated_list);
            setJobApplicants(data.job_applicants);
        } catch (error) {
            console.error("Failed to fetch notifications", error);
        }
    };

    useEffect(() => {
        // handleNotifications();
    }, [jobApplicants, setJobApplicants]);

    return {
        messagesNotify,
        setMessagesNotify,
        curatedListCount,
        setCuratedListCount,
        handleNotifications,
    };
};
