import { captureEvent } from "@/mixpanel-tracking/Mixpanel";
import { CurrUserDetails } from "@/utils/currUserType";

export class WaitingListTracker {
    page = "Accounting Vertical Waitlist Page";
    page_viewed = "Accounting Vertical Waitlist Page Viewed";
    page_exited = `${this.page_viewed} - Page Exited`;

    waitListClicked(employee: CurrUserDetails, position?: number) {
        captureEvent("Accounting Vertical Waitlist Button Clicked", {
            from: this.page,
            employee: employee,
            company: employee.company,
            position: position,
        });
    }

    page_entered(employee: CurrUserDetails, position?: number) {
        captureEvent(this.page_viewed, {
            from: this.page,
            employee: employee,
            company: employee.company,
            position: position ?? "N/A",
        });
    }

    page_closed(employee: CurrUserDetails, position?: number) {
        captureEvent(this.page_exited, {
            from: this.page,
            employee: employee,
            company: employee.company,
            position: position ?? "N/A",
        });
    }
}
