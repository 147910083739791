import i18n from "../../i18n";
import { Mixpanel, captureEvent } from "../Mixpanel";
import {
    prefix_to_object,
    getSubscriptionDetails,
    getActiveVertical,
} from "../utils";
import store from "@/core/stores";
import { getUser } from "@/contexts/UserContext";

export class ExtraTracker {
    state = store.getState();
    constructor(props) {
        this.vertical = this.state.selectedVertical.vertical;
        this.currUser = getUser();
        this.props = props;
    }
    contact_info_copied = (info, candidate, from, user_type) => {
        const eventData = {
            contact_info: info,
            from: from,
            ...prefix_to_object(candidate, "candidate_"),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        };
        if (user_type) {
            eventData.user_type = user_type;
        }
        captureEvent("Contact Information Copied", eventData);
    };
    document_viewed = () => {
        captureEvent("Legal Document Viewed", {
            document_name: this.props.doc_id,
        });
    };
    document_upload_clicked = () => {
        captureEvent("Legal Document Upload Button Clicked", {
            document_name: this.props.doc_id,
        });
    };
    link_copied = () => {
        captureEvent("Link Copied", {
            link: this.props.link,
            page_name: window.location.pathname,
            item_copied: this.props.item,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    language_change = () => {
        Mixpanel.register({
            language: i18n.language,
        });
    };
    email_verify = () => {
        Mixpanel.register({ verified: true });
        captureEvent("Email Verified");
    };
    tag_details = (name, tags) => {
        captureEvent("Tag Details Opened", {
            candidate_name: name,
            candidate_tags: tags,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    verify_request = (email) => {
        captureEvent("Verify Email Requested", {
            email: email,
        });
    };
    book_call = (hp, payload) => {
        captureEvent("HP Trial Ended Call Booked", {
            ...hp,
            ...payload,
        });
    };
    banner_button_clicked = (data) => {
        captureEvent("Banner Book a Call Button Clicked", {
            ...data,
        });
    };
    block_page_redirect = (data) => {
        captureEvent("Block Page Redirected", { ...data });
    };
    candidate_profile_switch = (user_type, extraAttributes) => {
        captureEvent("Candidate Profile Switch", {
            user_type,
            from: "Candidate Page",
            ...extraAttributes,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
}
