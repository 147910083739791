import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

const storedLang = localStorage.getItem('i18nextLng');

if(storedLang == undefined || (localStorage.getItem('i18nextLng') !== "en" 
&& localStorage.getItem('i18nextLng') !== "ar")) {
    localStorage.setItem('i18nextLng', 'ar')
}
i18n
    .use(I18nextBrowserLanguageDetector)
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'ar',
        debug: import.meta.env.VITE_STAGE === 'development' ? true : false,

        interpolation: {
        escapeValue: false // react already safes from xss
        },
        lng: storedLang
    });

export default i18n;