import React, { useContext } from "react";
import LocaleContext from "@/contexts/LocaleContext";
import { LanguageOutlined } from "@mui/icons-material";
import MenuItem from "../../../Header/components/menu-item/menu-item"; // Adjust the import path as necessary

interface LanguageButtonProps {
    changeLang: () => void;
    isExpended: boolean;
}

const LanguageButton: React.FC<LanguageButtonProps> = ({
    changeLang,
    isExpended,
}) => {
    const { locale } = useContext(LocaleContext);
    const getText = () => {
        return locale === "en" ? "العربية" : "English";
    };

    const getShortText = () => {
        return locale === "en" ? "ع" : "EN";
    };
    return (
        <MenuItem
            to="#"
            variant="default"
            active={false}
            onClick={changeLang}
            isSideBarOpen={isExpended}
            text={getText()}
            testId="language-button"
        >
            {isExpended && (
                <LanguageOutlined
                    sx={{ marginInlineStart: isExpended ? "-8px" : "auto" }}
                />
            )}
            <div className="label">{isExpended ? null : getShortText()}</div>
        </MenuItem>
    );
};

export default LanguageButton;
