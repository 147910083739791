import { captureEvent, trackTime } from "@/mixpanel-tracking/Mixpanel";
import { getActiveVertical, getSubscriptionDetails, prefix_to_object } from "@/mixpanel-tracking/utils";
import axiosInstance from "../../../static/axios";
import Verticals from "@/core/enums/verticals.enum";
import store from "@/core/stores";
import { FeatureFlag } from "@/feature-flag";

export enum NotificationChannel {
    InApp = "In-App",
    WhatsApp = "WhatsApp",
    Email = "Email",
}

abstract class NotificationTrackingBase {
    currUser: any;
    user_prefix: string;
    eventName: string;

    constructor(props: any, eventName: string) {
        this.currUser = props;
        this.user_prefix = `${this.currUser.views}_`;
        this.eventName = eventName;
    }

    captureEventWithData(event_name: string, data: any): void {
        captureEvent(event_name, {
            ...data,
            ...prefix_to_object(this.currUser, this.user_prefix),
        });
    }
}

export class NotificationBellTracking extends NotificationTrackingBase {
    notification_closed_event_name: string;
    counter_started: boolean;
    state = store.getState();
    vertical = this.state.selectedVertical.vertical;

    constructor(props: any) {
        super(props, "Notification Icon 'Bell' Clicked");
        this.notification_closed_event_name =
            "Notification Icon 'Bell' Clicked- Exited";
        this.counter_started = false;
    }

    opened(): void {
        this.captureEventWithData(this.eventName, {
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
        trackTime(this.notification_closed_event_name);
        this.counter_started = true;
    }

    closed(): void {
        this.captureEventWithData(this.notification_closed_event_name, {
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
        this.counter_started = false;
    }
}

export class NotificationItemTracking extends NotificationTrackingBase {
    state = store.getState();
    vertical = this.state.selectedVertical.vertical;
    constructor(props: any) {
        super(props, "Notification Clicked");
    }

    async click(
        notification: any,
        channel: NotificationChannel,
        extra_data: any
    ): Promise<void> {
        const event_data: any = {
            current_page: window.location.pathname,
            ...prefix_to_object(this.currUser, this.user_prefix),
            ...prefix_to_object(
                {
                    channel: channel,
                    category: extra_data.category,
                    trigger_time: new Date(
                        notification.created_on
                    ).toLocaleString(),
                    ...notification,
                },
                "notification_"
            ),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        };

        if (extra_data.job_id) {
            try {
                const job = await axiosInstance.get(
                    `/jobs/${extra_data.job_id}`
                );
                event_data.job = prefix_to_object(job.data.data || {}, "job_");
            } catch (e) {
                // no action
            }
        }
        const getUrl = () => {
            if (FeatureFlag.enable_marketing_v3()) {
                return this.vertical === Verticals.Marketing
                    ? "/applicant/marketing/get/"
                    : "/applicant/sales/get/";
            }
            return "/applicant/get/";
        };

        if (extra_data.candidate_id) {
            try {
                const candidate = await axiosInstance.get(
                    `${getUrl()}${extra_data.candidate_id}`
                );
                event_data.candidate = prefix_to_object(
                    candidate.data || {},
                    "employee_"
                );
            } catch (e) {
                // No action required for now
            }
        }

        if (extra_data.employee_id) {
            try {
                const employee = await axiosInstance.get(
                    `/employee/manage_employee/${extra_data.employee_id}`
                );
                event_data.employee = prefix_to_object(
                    employee.data || {},
                    "employee_"
                );
            } catch (e) {
                // No action
            }
        }

        this.captureEventWithData(this.eventName, event_data);
    }
}

export class NotificationSettingsTracking extends NotificationTrackingBase {
    constructor(props: any) {
        super(props, "Notification setting changed");
    }

    changed(event_data: any): void {
        this.captureEventWithData(this.eventName, event_data);
    }
}
