import React, { useEffect, useState } from "react";
import { Factory, Pages } from "@/mixpanel-tracking/hp/factory";
import { ExtraTracker } from "@/mixpanel-tracking/hp/extra-trackers";
import { gb } from "@/index";
import LocaleContext from "@/contexts/LocaleContext";
import i18n from "@/i18n";
import useLanguageChange from "@/hooks/useLanguageChange";

interface Props {
    children: React.ReactNode;
}
const LocaleProvider: React.FC<Props> = ({
    children,
}: Props): React.ReactElement => {
    const factory = new Factory();
    const track = factory.get_tracker(
        Pages.EXTRA_TRACKER
    ) as unknown as ExtraTracker;

    const { locale, setLocale } = useLanguageChange({
        trackLanguageChange: track.language_change,
        loadFeatures: gb.loadFeatures,
    });

    useEffect(() => {
        track.language_change();
    }, [locale]);
    return (
        <LocaleContext.Provider value={{ locale, setLocale: () => setLocale }}>
            {children}
        </LocaleContext.Provider>
    );
};

export default LocaleProvider;
