import { createSlice, createSelector } from "@reduxjs/toolkit";
import singletonLocalStorage from "@/utils/localStoring";

// Function to load initial state from localStorage
const loadInitialState = () => {
    const localFilters = singletonLocalStorage.getItem("filters");
    if (localFilters) {
        try {
            const parsedFilters = JSON.parse(localFilters);
            if (typeof parsedFilters === "object" && parsedFilters !== null) {
                return parsedFilters;
            }
        } catch (error) {
            console.error("Failed to parse filters from localStorage:", error);
        }
    }
    return {}; // Default initial state if no filters are found or parsing fails
};

const filterSlice = createSlice({
    name: "filters",
    initialState: loadInitialState(),
    reducers: {
        setFilters: (state, action) => {
            const { vertical, filters } = action.payload;
            state[vertical] = filters;
        },
        clearFilters: (state, action) => {
            const { vertical } = action.payload || {};
            if (vertical) {
                state[vertical] = [];
            }
        },
    },
});

const selectFilterList = (state) => state.filters;
const selectSelectedVertical = (state) => state.selectedVertical.vertical;

export const selectFiltersByVertical = createSelector(
    [selectFilterList, selectSelectedVertical],
    (filterList, selectedVertical) => {
        return filterList[selectedVertical] || [];
    }
);

export const { setFilters, clearFilters } = filterSlice.actions;

export default filterSlice.reducer;
