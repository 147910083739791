import { prefix_to_object } from "../utils";
import { captureEvent } from "../Mixpanel";
import { getUser } from "../../contexts/UserContext";

export class SignupWizardTracker {
    constructor(props) {
        this.props = props;
        this.currUser = getUser();

        this.signup_wizard_step_track_map = {
            0: { name: "Signup Wizard Started" },
            1: {
                name: "Signup wizard first question completed",
                ansKey: "selected_function",
            },
            2: {
                name: "Signup wizard second question completed",
                ansKey: "selected_seniority_level",
            },
            3: {
                name: "Signup wizard third question completed",
                ansKey: "tag_selected",
            },
            4: {
                name: "Signup wizard fourth question completed",
                ansKey: "selected_cities",
            },
        };
    }

    signup_wizard_step_track = (step, trackData) => {
        const mappedData = {
            free_trial: this.currUser.company.free_trial,
            ...prefix_to_object(this.currUser, "hp_"),
            ...trackData?.extraData,
        };
        const mappedStage = this.signup_wizard_step_track_map[step];
        if (mappedStage.ansKey) {
            mappedData[mappedStage.ansKey] = trackData.data;
        }
        captureEvent(mappedStage.name, { ...mappedData });
    };
}
