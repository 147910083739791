export const fake_candidate = {
    id: "8fbba25f-51c3-43a4-bebc-d1ccfc473dc1",
    tags: ["fast_track", "willing_to_relocate"],
    picture:
        "https://hired-dev-media-bucket.s3.me-south-1.amazonaws.com/public/defaultmale.png",
    cv: "ss",
    months_of_experience_range: "1 - 1.5 years",
    city: {
        id: 11,
        name_ar: "الرياض",
        name_en: "Riyadh",
    },
    is_shortlisted: false,
    university: {
        id: 5,
        name_ar: "جامعة الملك عبدالعزيز",
        name_en: "King Abdulaziz University",
    },
    salary_expectations: {
        id: 7,
        name_ar: "قابل للمناقشة",
        name_en: "Open to discussion",
    },
    job_type: ["دوام كامل"],
    major: {
        id: 59,
        name_ar: "التمويل",
        name_en: "Finance",
    },
    email: "demo@test.com",
    name: "محمد",
    gender: "Male",
    name_eng: "Mohammed",
    phone: "+9555555555",
    has_bachelors: false,
    demo: true,
    isListed: true,
    hired: false,
    field_work: true,
    remote_work: false,
    date_of_birth: "2000-10-10",
    is_date_of_birth_updated: false,
    participation_report: "",
    video_link: "wQmN52G9IB",
    video_interview_code: "wQmN52G9IB",
    english_proficiency: 2,
    status: null,
    cities_to_relocate: [],
    comments: [],
};

export const fake_candidate_new = {
    tags: ["fast_track", "b2c", "is_tamheer_eligible", "willing_to_relocate"],
    id: "8fbba25f-51c3-43a4-bebc-d1ccfc473dc1",
    picture:
        "https://hired-dev-media-bucket.s3.me-south-1.amazonaws.com/public/defaultmale.png",
    cv: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    months_of_experience_range: "1 - 2 years",
    city: {
        id: 11,
        name_ar: "الرياض",
        name_en: "Riyadh",
    },
    shortlist_details: {
        id: "98ec6c99-c78c-496a-8701-1149e72fcd32",
        position: 0,
        createdAt: "2024-02-04T19:36:25.169889Z",
    },
    is_shortlisted: false,
    university: {
        id: 30,
        name_ar: "الجامعة العربية المفتوحة",
        name_en: "Arab Open University",
    },
    salary_expectations: {
        id: 51,
        name_ar: "4 - 9 الآف",
        name_en: "4 - 9 K",
    },
    occupation: "Unemployed",
    job_type: {
        id: 2,
        name_ar: "دوام كامل",
        name_en: "Full-time",
    },
    major: {
        id: 96,
        name_ar: "التسويق",
        name_en: "Marketing",
    },
    cities_to_relocate: [],
    industries_worked_in: [
        {
            id: 15,
            name_ar: "القطاع التقني",
            name_en: "Technology",
        },
        {
            id: 13,
            name_ar: "بيع التجزئة",
            name_en: "Retail",
        },
    ],
    bootcamp_skills: [
        {
            grade: 4,
            grade_out_of: 6,
            skill: {
                id: 6,
                name_ar: "البحث والتأهيل",
                name_en: "Prospecting",
                is_suggested: false,
            },
        },
        {
            grade: 3,
            grade_out_of: 4,
            skill: {
                id: 2,
                name_ar: "اكتشاف العميل",
                name_en: "Discovering",
                is_suggested: false,
            },
        },
        {
            grade: 2,
            grade_out_of: 5,
            skill: {
                id: 5,
                name_ar: "عرض الحل",
                name_en: "Presenting Solutions",
                is_suggested: false,
            },
        },
        {
            grade: 4,
            grade_out_of: 6,
            skill: {
                id: 4,
                name_ar: "مصطلحات",
                name_en: "Terminology",
                is_suggested: false,
            },
        },
        {
            grade: 1,
            grade_out_of: 3,
            skill: {
                id: 1,
                name_ar: "التفاوض والإغلاق",
                name_en: "Negotiation & Closing",
                is_suggested: false,
            },
        },
        {
            grade: 3,
            grade_out_of: 4,
            skill: {
                id: 3,
                name_ar: "المتابعة",
                name_en: "Following up",
                is_suggested: false,
            },
        },
        {
            grade: 4,
            grade_out_of: 4,
            skill: {
                id: 400,
                name_ar: "كسر الجمود",
                name_en: "Ice-Breaking",
                is_suggested: false,
            },
        },
        {
            grade: 4,
            grade_out_of: 4,
            skill: {
                id: 81,
                name_ar: "إدارة الوقت",
                name_en: "time management ",
                is_suggested: true,
            },
        },
        {
            grade: 4,
            grade_out_of: 4,
            skill: {
                id: 403,
                name_ar: "التواصل مع العميل",
                name_en: "Interpersonal Communication",
                is_suggested: false,
            },
        },
        {
            grade: 4,
            grade_out_of: 4,
            skill: {
                id: 401,
                name_ar: "التواصل الشخصي",
                name_en: "Intrapersonal Communication",
                is_suggested: false,
            },
        },
        {
            grade: 4,
            grade_out_of: 4,
            skill: {
                id: 402,
                name_ar: "التحضير المسبق",
                name_en: "Readiness and Preparation",
                is_suggested: false,
            },
        },
    ],
    notice_period: {
        id: 1,
        name_ar: "بدء فوري",
        name_en: "Start immediately",
    },
    workplace_type: [
        {
            id: 1,
            name_ar: "حضوري",
            name_en: "On-Site",
        },
        {
            id: 3,
            name_ar: "هجين (حضور و عن بعد)",
            name_en: "Hybrid",
        },
    ],
    highest_education_level: {
        id: 3,
        name_ar: "بكالريوس",
        name_en: "Bachelor's degree",
    },
    cv_data: {
        experience: [
            {
                job_type: "full-time",
                end_date: "Present",
                is_sales_experience: true,
                workplace_type: {
                    id: 1,
                    name_en: "On-Site",
                    name_ar: "حضوري",
                },
                company_name: "Zid",
                responsibilies: [
                    "Act as a trusted advisor to entrepreneurs, providing guidance on the benefits of establishing an online presence and using the Zid platform for website development.",
                    "Conduct informative sessions and webinars to educate potential users on the features and capabilities of the Zid platform",
                ],
                job_title: "Affiliate marketing",
                start_date: "25/04/2023",
            },
            {
                job_type: "full-time",
                end_date: "26/12/2023",
                is_sales_experience: true,
                workplace_type: {
                    id: 1,
                    name_en: "On-Site",
                    name_ar: "حضوري",
                },
                responsibilities: [],
                company_name: "كليه تقنيه ",
                job_title: "سكرتير تنفيذي ",
                start_date: "25/04/2023",
            },
            {
                job_type: "full-time",
                end_date: "20/12/2020",
                is_sales_experience: true,
                workplace_type: {
                    id: 1,
                    name_en: "On-Site",
                    name_ar: "حضوري",
                },
                company_name: "Technical and Vocational Training Corporation",
                job_title: "Medical Secretary",
                start_date: "01/09/2020",
            },
        ],
        courses_certifications: [
            {
                duration: "4",
                issue_date: "25/09/2023",
                course_name: "Digital marketing",
                issuer_name: "Google",
            },
            {
                duration: "2",
                issue_date: "23/01/2022",
                course_name: "Excel",
                issuer_name: "Microsoft",
            },
        ],
        skills: [
            {
                id: 1,
                name_en: "Negotiation & Closing",
                name_ar: "التفاوض والإغلاق",
            },
            {
                id: 22,
                name_en: "conflict management",
                name_ar: "إدارة الصراع",
            },
            {
                id: 3,
                name_en: "problem-solving",
                name_ar: "حل المشكلات",
            },
        ],
        professional_summary:
            "كخريج جديد أنا شخص متحمس ومتحمس قد كملت دراستي موخرا وحصلت على شهاده في الاداره المكتبيه امتلك أساسا أكاديمية وقويا وشغفا للتعلم وتطبيق معرفتي في البيئات العلمية \nخلال دراستي طورت مجموعه متنوعه من المهارات ، بما في ذلك التفكير النقدي وحل المشكلات والتواصل الفعال . لدي فهم قوي للمفاهيم والمبادئ النظرية في مجال تخصصي ,   با الاضافه على القدره على تطبيقها في سيناريوهات العالم الحقيقي \nعلى الرغم من انني قد لاتتمتع بخبره مهنيه واسعه إلا انني حريص على المساهمة بمهارتي ومعرفتي لإحداث تاثير ايجابي ",
    },
    email: "demo@test.com",
    name: "محمد",
    gender: "Male",
    name_eng: "Mohammed",
    phone: "+9555555555",
    has_bachelors: false,
    demo: true,
    isListed: true,
    hired: false,
    field_work: true,
    remote_work: false,
    date_of_birth: "2000-10-10",
    is_date_of_birth_updated: false,
    participation_report: "",
    video_link: "wQmN52G9IB",
    video_interview_code: "wQmN52G9IB",
    english_proficiency: 2,
    status: null,
    comments: [],
    fake: true,
    email_verified: false,
    email_verification_date: null,
    onboarding_journey_status: "skipped",
    other_city: null,
    age: 21,
    birth_date: null,
    total_cv_parses: 1,
    version: 1,
    expected_salary: null,
    open_to_discussion: false,
    national_id: "1118998325",
    is_student: false,
    linkedin_profile_link: null,
    sales_experience_month: 20,
    total_experience_month: 20,
    fulltime_months_of_experience: 13,
    is_currently_employed: null,
    updated_at: "2024-02-04T19:09:29.147845Z",
    workplace_preference: null,
    profile_status: 2,
    is_tamheer_eligible: 1,
    company_size_preference: 2,
    salary_flexibility_preference: 4,
    workplace_pace_preference: 3,
    old_job_type: [],
};
