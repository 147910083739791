import React from "react";
import { Portal, Backdrop, Popover } from "@mui/material";
import i18n from "@/i18n";
import "./popover.scss";

interface Props {
    open: boolean;
    name: string;
    anchorEl: HTMLElement | null;
    handleClose: () => void;
    transformVert?: "top" | "bottom" | "center";
    withBackdrop?: boolean; // Prop to decide if a backdrop should be used
    children: React.ReactNode;
}

const ScalersPopover: React.FC<Props> = ({
    open,
    name,
    anchorEl,
    handleClose,
    transformVert = "top",
    withBackdrop = false,
    children,
}) => {
    const dir = i18n.dir(); // Automatically determine the direction

    // Function to determine the horizontal position based on direction
    const anchorHorizontalPosition = (
        direction: "ltr" | "rtl"
    ): "left" | "right" => {
        return direction === "ltr" ? "left" : "right";
    };

    const popoverContent = (
        <Popover
            id={name}
            className="popover"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            transformOrigin={{
                vertical: transformVert,
                horizontal: anchorHorizontalPosition(dir),
            }}
            onClick={(e) => e.stopPropagation()}
        >
            {children}
        </Popover>
    );

    return (
        <Portal>
            {withBackdrop ? (
                <Backdrop
                    invisible={window.innerWidth <= 768 ? false : true}
                    open={open}
                >
                    {popoverContent}
                </Backdrop>
            ) : (
                popoverContent
            )}
        </Portal>
    );
};

export default ScalersPopover;
