import { captureEvent } from "../Mixpanel";
import {
    prefix_to_object,
    parse_query_params,
    free_trial_props,
} from "../utils";
import { getUser } from "../../contexts/UserContext";

export class MessagesTracking {
    constructor(props) {
        this.currUser = getUser();
        this.currUserRef = props.currUserRef;
        this.messageSelectedRef = props.messageSelectedRef;
        this.source = parse_query_params().source;
    }

    message_sent = (message, content) => {
        captureEvent("Message Sent", {
            ...prefix_to_object(message.candidate, "receiver_"),
            ...prefix_to_object(this.currUser, "sender_"),
            content: content,
            new_convo: message.status == "Pending" ? true : false,
            ...free_trial_props(this.currUser),
        });
    };
    conversation_toggled = (message) => {
        captureEvent("Conversation Toggled", {
            ...prefix_to_object(message.candidate, "candidate_"),
            state:
                message.status == "Closed"
                    ? "Conversation Re-Opened"
                    : "Conversation Closed",
        });
    };
    conversation_read = (message) => {
        captureEvent("Conversation Read", {
            From: this.source,
            ...prefix_to_object(this.currUser, "reader_"),
            ...prefix_to_object(message.candidate, "sender_"),
            ...free_trial_props(this.currUser),
        });
    };
    draft_created = (conversation, newMessageContent) => {
        captureEvent("Draft Created", {
            ...prefix_to_object(this.currUserRef.current, "sender_"),
            ...prefix_to_object(conversation.candidate, "on_chat_with_"),
            content: newMessageContent
                ? newMessageContent
                : conversation.message.content,
        });
    };
    draft_discarded = () => {
        captureEvent("Draft Discarded", {
            sender: this.currUserRef.current.name,
            ...prefix_to_object(
                this.messageSelectedRef.current.candidate,
                "on_chat_with_"
            ),
            content_before: this.messageSelectedRef.current.content,
        });
    };
}
