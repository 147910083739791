import "../../../static/css/modal.scss";
import close_img from "../../../static/images/close.png";
import React, { useContext } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import ScalersTypography from "../atoms/typography/typography";
import LocaleContext from "@/contexts/LocaleContext";
import { useNavigate } from "react-router-dom";

// Modal to send interview request
function ErrorModal(props) {
    const { type, t, candidate } = props;
    const [message, setMessage] = useState("");
    const { locale } = useContext(LocaleContext);
    const navigate = useNavigate();

    const hiredModal = type === "candidate-hired";

    const closeModal = () => {
        if (hiredModal) return;
        document.getElementById(type).style.display = "none";
    };

    useEffect(() => {
        setMessage(props.message);
    }, [props]);
    return (
        <div
            id={type}
            className="modal"
            style={{ display: "none" }}
            onClick={closeModal}
        >
            <div
                id="error-modal-content"
                className="modal-content"
                onClick={(e) => {
                    e.stopPropagation();
                    return;
                }}
            >
                {hiredModal ? (
                    <Stack alignItems="center" gap={1}>
                        <img
                            className="candidate-img"
                            src={candidate?.picture}
                        />
                        <ScalersTypography
                            width="100%"
                            fontWeight={600}
                            variant="h6"
                        >
                            {locale === "en" ? (
                                <>
                                    {t("candidate-profile.hired_modal.sorry")}{" "}
                                    {candidate?.name_eng?.split(" ")[0]}{" "}
                                    {t("candidate-profile.hired_modal.hired")}
                                </>
                            ) : (
                                <>
                                    {t(
                                        "candidate-profile.hired_modal.hired_full"
                                    )}
                                    {candidate?.name?.split(" ")[0]}
                                </>
                            )}
                        </ScalersTypography>
                        <ScalersTypography
                            width="100%"
                            fontWeight={500}
                            variant="body1"
                        >
                            {t("candidate-profile.hired_modal.description")}
                        </ScalersTypography>
                        <Button
                            sx={{
                                maxWidth: "unset !important",
                            }}
                            variant="contained"
                            onClick={() => navigate("/hp/candidates")}
                            color="primary"
                        >
                            {t("candidate-profile.hired_modal.button-text")}
                        </Button>
                    </Stack>
                ) : (
                    <>
                        <div className="top-error">
                            <FiAlertCircle size={36} />
                        </div>
                        {type == "error-modal" ? (
                            <p>{t("error_messages.default")}</p>
                        ) : (
                            <p
                                dangerouslySetInnerHTML={{ __html: message }}
                            ></p>
                        )}
                        <button onClick={closeModal}>{t("close")}</button>
                        <img
                            src={close_img}
                            className="modal-close"
                            onClick={closeModal}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default ErrorModal;
