import { useNavigate } from "react-router-dom";
import React from "react";
import "../../../static/css/logout.css"; // Import css
import axiosInstance from "../../../static/axios";
import { Factory, Pages } from "../../mixpanel-tracking/hp/factory";
import singletonLocalStorage from "../../utils/localStoring";
import { clearSpecificsFromLocal } from "../../utils/utilFunctions";

function LogoutModal(props) {
    const { t } = props;
    let navigate = useNavigate();
    const factory = new Factory();
    let track = factory.get_tracker(Pages.LOGIN_LOGOUT_SIGNUP);

    const handleExit = (e) => {
        document.getElementById("logout-modal").style.display = "none";
    };

    const handleLogout = (e) => {
        e.stopPropagation();
        axiosInstance({
            method: "post",
            url: "user/logout/",
            data: {
                refresh_token: singletonLocalStorage.getItem("refresh_token"),
            },
        })
            .then((res) => {
                clearSpecificsFromLocal();
                track.logout_complete(false);
                navigate("/", { replace: true });
            })
            .catch((er) => {
                clearSpecificsFromLocal();
                track.logout_complete(false);
                navigate("/", { replace: true });
            });
        handleExit();
    };

    return (
        <div
            id="logout-modal"
            className="logout"
            style={{ display: "none" }}
            onClick={handleExit}
        >
            <div
                id="logout-modal-content"
                className="modal-content"
                onClick={(e) => {
                    e.stopPropagation();
                    return;
                }}
            >
                <h4>{t("logout.message")}</h4>
                <div>
                    <button className="outlined-button" onClick={handleExit}>
                        {t("cancel")}
                    </button>
                    <button className="filled-button" onClick={handleLogout}>
                        {t("ok")}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LogoutModal;
