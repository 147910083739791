import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useState,
} from "react";

type JobSetNotifyContext = {
    jobApplicants: JobNotifyContext;
    setJobApplicants: Dispatch<SetStateAction<JobNotifyContext>>;
};

export const defaultValue: JobSetNotifyContext = {
    jobApplicants: {
        has_job_applicants: false,
        show_banner: false,
        navigate_to: "",
        total_number: 0,
    },
    setJobApplicants: () => {},
};
const JobNotifyContext = createContext(defaultValue.jobApplicants);
const JobNotifySetContext = createContext<
    Dispatch<SetStateAction<JobNotifyContext>>
>(defaultValue.setJobApplicants);

export type JobNotifyContext = {
    has_job_applicants: boolean;
    show_banner: boolean;
    navigate_to: string;
    total_number: number;
};

interface Props {
    children: React.ReactNode;
}

export const getJobApplicants = () => {
    return useContext(JobNotifyContext);
};

export const getSetJobApplicants = () => {
    return useContext(JobNotifySetContext);
};

export const JobNotifyProvider = (props: Props) => {
    const [jobApplicants, setJobApplicants] = useState(
        defaultValue.jobApplicants
    );

    return (
        <JobNotifyContext.Provider value={jobApplicants}>
            <JobNotifySetContext.Provider value={setJobApplicants}>
                {props.children}
            </JobNotifySetContext.Provider>
        </JobNotifyContext.Provider>
    );
};
