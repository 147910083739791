import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import useMediaBreakpoint from "../../../../hooks/useMediaBreakpoint";
import "./menu-item.scss";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import LocaleContext from "../../../../contexts/LocaleContext";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setNavigateAwayLink } from "@/core/stores/general/general.store";

export const LightTooltip = styled(({ className, ...props }) => {
    const { title, show, children, notification } = props;
    return show ? (
        <Tooltip
            title={
                <>
                    {title} {notification}
                </>
            }
            classes={{ popper: className }}
            placement="right"
        >
            {children}
        </Tooltip>
    ) : (
        <>{children}</>
    );
})(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow:
            "0px 8px 16px 0px rgba(108, 47, 131, 0.10), 0px 0px 1px 0px rgba(108, 47, 131, 0.05);",
        padding: "10px 8px 10px 8px",
        borderRadius: "8px",
        fontWeight: "500",
        textAlign: "center",
        fontSize: 16,
    },
}));

const MenuItem = ({
    to,
    text,
    onClick,
    active,
    danger,
    notification,
    children,
    testId,
    isNew = false,
    isSideBarOpen = false,
    variant = "primary", // default to 'primary' if not specified,
    isDisabled = false,
}) => {
    const { t } = useTranslation();
    const useMediaQuery = useMediaBreakpoint();
    const isMobileView = ["xs", "sm"].includes(useMediaQuery.breakpoint);
    const location = useLocation();
    const isActive = location.pathname === to;
    const displayText = () => isMobileView || isSideBarOpen;
    const displayNewTag = () => (isMobileView || isSideBarOpen) && isNew;
    const showTooltip = () => !isSideBarOpen && !isMobileView;
    const { locale } = React.useContext(LocaleContext);

    // Apply variant-specific class
    const variantClass = `menu-item--${variant}`;

    const dispatch = useDispatch();
    const navigateAwayLink = useSelector(
        (state) => state.general.navigateAwayLink
    );

    return (
        <LightTooltip
            title={text}
            show={showTooltip()}
            notification={notification}
            className={locale}
        >
            <Link
                className={clsx("menu-item", variantClass, {
                    "text-button-danger": danger,
                    [`${variantClass}--active`]: active,
                    "menu-item-justify": !isSideBarOpen,
                    "menu-item--disabled": isDisabled,
                })}
                to={to}
                onClick={(e) => {
                    if (navigateAwayLink !== "" && to !== "#") {
                        e.preventDefault();
                        e.stopPropagation();
                        document.getElementById(
                            "exit-disclaimer-modal"
                        ).style.display = "flex";
                        dispatch(setNavigateAwayLink(to));
                    } else {
                        if (isDisabled) {
                            e.preventDefault();
                        }
                        if (isActive) {
                            e.preventDefault();
                        } else {
                            onClick(e);
                        }
                    }
                }}
                data-testid={`${MenuItem.name}-${testId}`}
            >
                {children}
                {displayText() && text}
                {notification}
                {displayNewTag() && <div className="new-tag">{t("new")}</div>}
            </Link>
        </LightTooltip>
    );
};

export default MenuItem;
