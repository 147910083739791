import "../../../static/css/modal.scss";
import React from "react";
import close_img from "../../../static/images/close.png";
import { FiCheckCircle } from "react-icons/fi";

// Modal to send interview request
function FeedbackSentModal(props) {
    const { t } = props;
    const closeModal = () => {
        document.getElementById("feedback-success-modal").style.display =
            "none";
    };
    return (
        <div
            id="feedback-success-modal"
            className="modal"
            style={{ display: "none" }}
            onClick={closeModal}
        >
            <div
                id="success-modal-content"
                className="modal-content"
                onClick={(e) => {
                    e.stopPropagation();
                    return;
                }}
            >
                <div className="top-success">
                    <FiCheckCircle size={46} />
                </div>
                <h4>{t("thanks_feedback")}</h4>
                <p>{t("contact_us.secondary")}</p>
                <button className="success-btn" onClick={closeModal}>
                    {t("close")}
                </button>
                <img
                    src={close_img}
                    className="modal-close"
                    onClick={closeModal}
                />
            </div>
        </div>
    );
}

export default FeedbackSentModal;
