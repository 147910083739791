import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import { CloseModalContext } from "./Context";

export default function CancelAction(props) {
    const { closeModal } = useContext(CloseModalContext)

    return (
        <Button variant="contained" className="secondary" onClick={() => {
            closeModal()
        }}>{props.buttonContent}</Button>
    )
}