import React from "react";
import "./_layout.scss";
import ScalersTypography from "@/components/atoms/typography/typography";

export type LayoutProps = {
    title?: React.ReactNode;
    children: React.ReactNode;
};

export default function ScalersLayout({ title, children }: LayoutProps) {
    return (
        <div className="layout">
            <div className="content">
                {
                    <ScalersTypography
                        variant="h1"
                        fontWeight={700}
                        color="#2A2731"
                        className="title"
                    >
                        {title}
                    </ScalersTypography>
                }
                {children}
            </div>
        </div>
    );
}
