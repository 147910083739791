import React, { useContext } from "react";
import "./sidebar-button.scss";
import {
    KeyboardDoubleArrowRight,
    KeyboardDoubleArrowLeft,
} from "@mui/icons-material";
import LocaleContext from "../../../../contexts/LocaleContext";
import { useTranslation } from "react-i18next";
import { LightTooltip } from "../../../Header/components/menu-item/menu-item";
import clsx from "clsx";

function SidebarButton({ isSideBarOpen, setSideBarVisibility }) {
    const showSideBar = () => setSideBarVisibility(true);
    const hideSidebar = () => setSideBarVisibility(false);
    const { t } = useTranslation();
    const { locale } = useContext(LocaleContext);

    const sidebarButtonDirection = () => {
        if (locale === "en") {
            return isSideBarOpen ? (
                <KeyboardDoubleArrowLeft fontSize="small" />
            ) : (
                <KeyboardDoubleArrowRight fontSize="small" />
            );
        }
        if (locale === "ar") {
            return isSideBarOpen ? (
                <KeyboardDoubleArrowRight fontSize="small" />
            ) : (
                <KeyboardDoubleArrowLeft fontSize="small" />
            );
        }
    };

    return (
        <div
            key="expand-collapse-div"
            className="expand-collapse-button"
            onClick={isSideBarOpen ? hideSidebar : showSideBar}
            data-testid={SidebarButton.name}
        >
            <LightTooltip title={t("expand")} show={!isSideBarOpen}>
                <div
                    className={clsx("sidebar-button", {
                        "sidebar-button-bottom-margin": true,
                    })}
                >
                    {sidebarButtonDirection()}
                    {isSideBarOpen && (
                        <span className="condense"> {t("condense")}</span>
                    )}
                </div>
            </LightTooltip>
        </div>
    );
}

export default SidebarButton;
