import singletonLocalStorage from "@/utils/localStoring";

export const filtersLocalStorageMiddleware = (store) => (next) => (action) => {
    const result = next(action);

    if (
        action.type === "filters/setFilters" ||
        action.type === "filters/clearFilters"
    ) {
        const state = store.getState();
        singletonLocalStorage.setItem("filters", JSON.stringify(state.filters));
    }

    return result;
};
