import { useEffect, useState } from "react";
import i18n from "@/i18n";
import { useTranslation } from "react-i18next";
import type { LoadFeaturesOptions } from "@growthbook/growthbook/src/types/growthbook";

interface LanguageChangeActions {
    trackLanguageChange: () => void;
    loadFeatures: (options?: LoadFeaturesOptions) => Promise<void>;
}

const useLanguageChange = ({
    trackLanguageChange,
    loadFeatures,
}: LanguageChangeActions) => {
    const { t } = useTranslation();
    const [locale, setLocale] = useState(i18n.language);

    useEffect(() => {
        const handleLanguageChange = () => {
            setLocale(i18n.language);
            document.body.dir = i18n.dir();
            trackLanguageChange();
        };

        i18n.on("languageChanged", handleLanguageChange);
        // loadFeatures({ autoRefresh: true });

        // Cleanup function to remove the event listener
        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, [trackLanguageChange, loadFeatures]);

    return { locale, setLocale, t };
};

export default useLanguageChange;
