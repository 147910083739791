import React from "react";
import "./menu-notification.css";
import clsx from "clsx";
import { useSelector } from "react-redux";
import Verticals from "@/core/enums/verticals.enum";

const MenuItemNotification = (props) => {
    const vertical = useSelector((state) => state.selectedVertical.vertical);
    return (
        <>
            {props.link.path.includes("message") &&
                vertical !== Verticals.Accounting &&
                props.messagesNotify && (
                    <div
                        className={clsx("notify-wrapper", {
                            "notify-en": props.locale === "en",
                            "notify-ar": props.local !== "en",
                            "notify-wrapper-size-collapsed":
                                !props.isSideBarOpen,
                            "notify-wrapper-size-expanded": props.isSideBarOpen,
                        })}
                    >
                        {props.isSideBarOpen && (
                            <section
                                className={clsx("notify", {
                                    "notify-padding": props.os === "Mac",
                                })}
                            ></section>
                        )}
                    </div>
                )}
            {props.link.path.includes("jobs") &&
                vertical !== Verticals.Accounting &&
                props.jobApplicants.total_number > 0 && (
                    <div
                        className={clsx("notify-wrapper", {
                            "notify-en": props.locale === "en",
                            "notify-ar": props.local !== "en",
                            "notify-wrapper-size-collapsed":
                                !props.isSideBarOpen,
                            "notify-wrapper-size-expanded": props.isSideBarOpen,
                        })}
                    >
                        <section
                            className={clsx("notify", {
                                "notify-padding": props.os === "Mac",
                            })}
                        >
                            {props.jobApplicants.total_number}
                        </section>
                    </div>
                )}
            {props.link.path.includes("curated") &&
                vertical !== Verticals.Accounting &&
                props.curatedListCount > 0 && (
                    <div
                        className={clsx("notify-wrapper", {
                            "notify-en": props.locale === "en",
                            "notify-ar": props.local !== "en",
                            "notify-padding": props.os === "Mac",
                            "notify-wrapper-size-collapsed":
                                !props.isSideBarOpen,
                            "notify-wrapper-size-expanded": props.isSideBarOpen,
                        })}
                    >
                        <section
                            className={clsx("notify", {
                                "notify-padding": props.os === "Mac",
                            })}
                        >
                            {props.curatedListCount}
                        </section>
                    </div>
                )}
        </>
    );
};

export default MenuItemNotification;
