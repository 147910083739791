import singletonLocalStorage from "@/utils/localStoring";
import { getUserSingleton } from "../user/user.store";

export const verticalMiddleware = (store) => (next) => (action) => {
    if (action.type === "selectedVertical/setSelectedVertical") {
        singletonLocalStorage.setItem("vertical", action.payload);
    }

    return next(action);
};
