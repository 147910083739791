import React, { FC } from "react";

interface Props {
    content:any;
}
const ModalSubSubtitle: FC<Props> = ({ content }: Props) => {

    return (
        <p className="sub-subtitle">
            {content}
        </p>
    )
}

export default ModalSubSubtitle